import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Button, FormControlLabel, Modal, Switch,
} from '@mui/material';
import Swal from 'sweetalert2';
import { TbDeviceFloppy, TbPlayerPlay, TbPlayerPause } from 'react-icons/tb';
import { MdOutlineDeleteForever } from 'react-icons/md';
import FlowBuilder, { NodeContext } from 'react-flow-builder';
import './Flow.scss';
import { Howl } from 'howler';
import { fetchNodeTypes } from '../../Views/FlowBuilderView/flowNodeAPI';
import nodeExtras from './NodeExtras.json';
import { fetchAudioSampleAction } from '../../Views/FlowBuilderView/flowNodeActions';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function Flow({
  flowData, onFieldChange, onNodeAddition, elevenlabsVoiceId,
}) {
  const { welcomeMessage, endMessage, nodes } = flowData;
  const [nodeTypes, setNodeTypes] = useState([]);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPlayingId, setCurrentPlayingId] = useState(null);
  const playerRef = useRef(null);
  const { getConfig, refresh, logout } = useContext(UserContext);

  const playAudio = (audioUrl, id) => {
    if (playerRef.current) {
      playerRef.current.stop();
    }

    const newPlayer = new Howl({
      src: [audioUrl],
      autoplay: false,
      format: ['mp3'],
      onplay: () => {
        setIsPlaying(true);
        setCurrentPlayingId(id);
      },
      onend: () => {
        setIsPlaying(false);
        setCurrentPlayingId(null);
      },
      onpause: () => {
        setIsPlaying(false);
        setCurrentPlayingId(null);
      },
    });

    playerRef.current = newPlayer;
    newPlayer.play();
  };

  const pauseAudio = () => {
    if (playerRef.current) {
      playerRef.current.pause();
    }
  };

  const flowNodes = [
    { type: 'start', id: 'start', nextId: nodes.length > 0 ? nodes[0].id : null },
    ...nodes.map((node, index, array) => ({
      ...node,
      nodeType: node.type,
      type: 'node',
      prevId: index === 0 ? 'start' : array[index - 1].id,
      nextId: index === nodes.length - 1 ? 'add-last' : array[index + 1].id,
    })),
    {
      type: 'add-node', id: 'add-last', prevId: nodes.length > 0 ? nodes[nodes.length - 1].id : 'start', nextId: 'end',
    },
    { type: 'end', id: 'end', prevId: 'add-last' },
  ];

  // eslint-disable-next-line max-len
  const fetchAudioSample = async (text, sampleKey) => fetchAudioSampleAction(text, elevenlabsVoiceId, sampleKey, getConfig, refresh, logout);

  useEffect(() => {
    const getNodeTypes = async () => {
      const fetchedNodeTypes = await fetchNodeTypes();
      setNodeTypes(fetchedNodeTypes);
    };
    getNodeTypes();
  }, []);

  function StartNodeDisplay() {
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState(welcomeMessage);
    const [loadingStart, setLoadingStart] = useState(false);

    return (
      <>
        {/* eslint-disable-next-line */}
      <div className="flow-node welcome-message" onClick={() => setModalOpen(true)}>
        <span>Welcome Message:</span>
        {welcomeMessage || 'Welcome!'}
        <Button
          className="btn btn-green"
          onClick={(e) => {
            e.stopPropagation();
            if (isPlaying && currentPlayingId === 'welcome_message') {
              pauseAudio();
            } else {
              setLoadingStart(true);
              fetchAudioSample(message, 'welcome_message')
                .then((audio) => {
                  playAudio(audio, 'welcome_message');
                  setLoadingStart(false);
                }).catch((error) => {
                  console.error('Error fetching audio sample:', error);
                  setLoadingStart(false);
                });
            }
          }}
          sx={{ ml: 2 }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {loadingStart ? (
            <div className="spinner" />
          ) : (
            isPlaying && currentPlayingId === 'welcome_message' ? <TbPlayerPause /> : <TbPlayerPlay />
          )}
        </Button>
      </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="modal-content edit-node-modal">
            <h4>Edit Welcome Message</h4>
            <label>Welcome Message</label>
            <input
              type="text"
              placeholder="Welcome Message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              className="btn btn-yellow"
              startIcon={<TbDeviceFloppy />}
              onClick={() => onFieldChange('welcome_message', message)}
              sx={{ mt: 2 }}
            >
              Save Message
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  function EndNodeDisplay() {
    const [modalOpen, setModalOpen] = useState(false);
    const [message, setMessage] = useState(endMessage);
    const [loadingEnd, setLoadingEnd] = useState(false);

    return (
      <>
        {/* eslint-disable-next-line */}
      <div className="flow-node end-message" onClick={() => setModalOpen(true)}>
        <span>End Message:</span>
        {endMessage || 'End of Flow'}
        <Button
          className="btn btn-green"
          onClick={(e) => {
            e.stopPropagation();
            if (isPlaying && currentPlayingId === 'end_message') {
              pauseAudio();
            } else {
              setLoadingEnd(true);
              fetchAudioSample(message, 'end_message')
                .then((audio) => {
                  playAudio(audio, 'end_message');
                  setLoadingEnd(false);
                }).catch((error) => {
                  console.error('Error fetching audio sample:', error);
                  setLoadingEnd(false);
                });
            }
          }}
          sx={{ ml: 2 }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {loadingEnd ? (
            <div className="spinner" />
          ) : (
            isPlaying && currentPlayingId === 'end_message' ? <TbPlayerPause /> : <TbPlayerPlay />
          )}
        </Button>
      </div>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="modal-content edit-node-modal">
            <h4>Edit End Message</h4>
            <label>End Message</label>
            <input
              type="text"
              placeholder="End Message..."
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button
              className="btn btn-yellow"
              startIcon={<TbDeviceFloppy />}
              onClick={() => onFieldChange('end_message', message)}
              sx={{ mt: 2 }}
            >
              Save Message
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  function OtherNodeDisplay() {
    const node = useContext(NodeContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [loadingOther, setLoadingOther] = useState(false);
    const [question, setQuestion] = useState(node.question);
    const [name, setName] = useState(node.name);
    const [type, setType] = useState(node.nodeType);
    const [required, setRequired] = useState(node.required);
    const [extraFields, setExtraFields] = useState({});
    const [options, setOptions] = useState([]);

    useEffect(() => {
      if (nodeExtras[type]) {
        const initialExtraFields = {};
        nodeExtras[type].fields.forEach((field) => {
          initialExtraFields[field.backend_name] = node[field.backend_name] || '';
        });
        setExtraFields(initialExtraFields);

        if (type === 'select' && initialExtraFields.options) {
          setOptions(initialExtraFields.options.split(','));
        }
      }
    }, [type, node]);

    const handleNodeChange = () => {
      const updatedFields = { ...extraFields };

      if (type === 'select') {
        updatedFields.options = options.join(',');
      }
      node.onChange(node.id, {
        question, name, type, required, ...updatedFields,
      });
      setModalOpen(false);
    };

    const handleExtraFieldChange = (fieldKey, value) => {
      setExtraFields((prevFields) => ({ ...prevFields, [fieldKey]: value }));
    };

    const handleOptionChange = (index, value) => {
      const newOptions = [...options];
      newOptions[index] = value;
      setOptions(newOptions);
    };

    const handleAddOption = () => {
      setOptions([...options, '']);
    };

    const handleDeleteOption = (index) => {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    };

    const handleDeleteNode = (nodeId) => {
      Swal.fire({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Are you sure you want to delete this node? This action cannot be undone!',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          node.onDelete(nodeId);
          setModalOpen(false);
        }
      });
    };

    const handleModalClose = () => {
      setModalOpen(false);
      setQuestion(node.question);
      setName(node.name);
      setType(node.nodeType);
      setRequired(node.required);
      if (nodeExtras[type]) {
        const initialExtraFields = {};
        nodeExtras[type].fields.forEach((field) => {
          initialExtraFields[field.backend_name] = node[field.backend_name] || '';
        });
        setExtraFields(initialExtraFields);

        if (type === 'select' && initialExtraFields.options) {
          setOptions(initialExtraFields.options.split(','));
        }
      }
    };

    return (
      <>
        {/* eslint-disable-next-line */}
      <div
        className="flow-node"
        onClick={() => setModalOpen(true)}
      >
        <span>Name:</span>
        {node.name}
        <span>Question:</span>
        {node.question}
        <span>Type:</span>
        {node.nodeType}
        <span>
          Is Required:
        </span>
        {node.required ? 'Yes' : 'No'}
        <Button
          className="btn btn-green"
          onClick={(e) => {
            e.stopPropagation();
            if (isPlaying && currentPlayingId === node.id) {
              pauseAudio();
            } else {
              setLoadingOther(true);
              fetchAudioSample(node.question, node.id)
                .then((audio) => {
                  playAudio(audio, node.id);
                  setLoadingOther(false);
                }).catch((error) => {
                  console.error('Error fetching audio sample:', error);
                  setLoadingOther(false);
                });
            }
          }}
          sx={{ ml: 2 }}
        >
          {/* eslint-disable-next-line no-nested-ternary */}
          {loadingOther ? (
            <div className="spinner" />
          ) : (
            isPlaying && currentPlayingId === node.id ? <TbPlayerPause /> : <TbPlayerPlay />
          )}
        </Button>
      </div>
        <Modal open={modalOpen} onClose={handleModalClose}>
          <div className="modal-content edit-node-modal">
            <h4>Edit Node</h4>
            <label>Name</label>
            <input
              type="text"
              placeholder="Name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Question</label>
            <input
              type="text"
              placeholder="Question..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <label>Type</label>
            <select
              className="select-box"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              {nodeTypes.map((nt) => (
                <option key={nt.key} value={nt.key}>{nt.value}</option>
              ))}
            </select>
            {nodeExtras[type] && nodeExtras[type].fields.map((field) => (
              <div key={field.backend_name}>
                <label>{field.frontend_name}</label>
                {field.backend_name === 'options' && type === 'select' ? (
                  <>
                    {options.map((option, index) => (
                      <div key={index} className="option-container">
                        <input
                          type="text"
                          placeholder="Option..."
                          value={option}
                          onChange={(e) => handleOptionChange(index, e.target.value)}
                        />
                        <Button className="btn-remove-option" onClick={() => handleDeleteOption(index)}>X</Button>
                      </div>
                    ))}
                    <Button className="btn-add-option" onClick={handleAddOption}>Add Option</Button>
                  </>
                ) : (
                  <input
                    type={field.type}
                    placeholder={field.frontend_name}
                    value={extraFields[field.backend_name]}
                    onChange={(e) => handleExtraFieldChange(field.backend_name, e.target.value)}
                  />
                )}
              </div>
            ))}
            <FormControlLabel
              sx={{ ml: '-5px' }}
              control={(
                <Switch
                  checked={required}
                  onChange={(e) => setRequired(e.target.checked)}
                  size="small"
                />
            )}
              label="Required"
            />
            <div className="flow-node-modal-buttons">
              <Button
                className="btn btn-red"
                startIcon={<MdOutlineDeleteForever />}
                onClick={() => handleDeleteNode(node.id)}
              >
                Delete Node
              </Button>
              <Button
                className="btn btn-yellow"
                startIcon={<TbDeviceFloppy />}
                onClick={handleNodeChange}
              >
                Save Node
              </Button>
            </div>
          </div>
        </Modal>
      </>
    );
  }

  function AddNodeButton() {
    const [modalOpen, setModalOpen] = useState(false);
    const [name, setName] = useState('');
    const [question, setQuestion] = useState('');
    const [type, setType] = useState('');
    const [required, setRequired] = useState(false);
    const [extraFields, setExtraFields] = useState({});
    const [options, setOptions] = useState([]);

    const isSaveEnabled = question.length > 0 && type.length > 0;

    useEffect(() => {
      if (nodeExtras[type]) {
        const initialExtraFields = {};
        nodeExtras[type].fields.forEach((field) => {
          initialExtraFields[field.backend_name] = '';
        });
        setExtraFields(initialExtraFields);

        if (type === 'select' && initialExtraFields.options) {
          setOptions(initialExtraFields.options.split(','));
        }
      }
    }, [type]);

    const handleExtraFieldChange = (fieldKey, value) => {
      setExtraFields((prevFields) => ({ ...prevFields, [fieldKey]: value }));
    };

    const handleSaveNode = () => {
      let lastNodeId = null;
      try {
        lastNodeId = nodes[nodes.length - 1].id;
      } catch (error) {
        console.log('No preceding nodes found');
      }

      const updatedFields = { ...extraFields };

      if (type === 'select') {
        updatedFields.options = options.join(',');
      }

      onNodeAddition(lastNodeId, question, name, type, required, updatedFields);

      setName('');
      setQuestion('');
      setType('');
      setRequired(false);
      setExtraFields({});
      setModalOpen(false);
    };

    const handleOptionChange = (index, value) => {
      const newOptions = [...options];
      newOptions[index] = value;
      setOptions(newOptions);
    };

    const handleAddOption = () => {
      setOptions([...options, '']);
    };

    const handleDeleteOption = (index) => {
      const newOptions = options.filter((_, i) => i !== index);
      setOptions(newOptions);
    };

    return (
      <>
        <Button className="btn btn-yellow add-node-btn" onClick={() => setModalOpen(true)}>
          Add Node
        </Button>
        <Modal open={modalOpen} onClose={() => setModalOpen(false)}>
          <div className="modal-content edit-node-modal">
            <h4>Add New Node</h4>
            <label>Name</label>
            <input
              type="text"
              placeholder="Node Name..."
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <label>Question</label>
            <input
              type="text"
              placeholder="Question..."
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
            />
            <label>Type</label>
            <select
              className="select-box"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <option value="">Select Type</option>
              {' '}
              {nodeTypes.map((nt) => (
                <option key={nt.key} value={nt.key}>{nt.value}</option>
              ))}
            </select>
            {nodeExtras[type] && nodeExtras[type].fields.map((field) => (
              <div key={field.backend_name}>
                <label>{field.frontend_name}</label>
                {field.backend_name === 'options' && type === 'select' ? (
                  <>
                    {options.map((option, index) => (
                      <div key={index} className="option-container">
                        <input
                          type="text"
                          placeholder="Option..."
                          value={option}
                          onChange={(e) => handleOptionChange(index, e.target.value)}
                        />
                        <Button className="btn-remove-option" onClick={() => handleDeleteOption(index)}>X</Button>
                      </div>
                    ))}
                    <Button className="btn-add-option" onClick={handleAddOption}>Add Option</Button>
                  </>
                ) : (
                  <input
                    type={field.type}
                    placeholder={field.frontend_name}
                    value={extraFields[field.backend_name]}
                    onChange={(e) => handleExtraFieldChange(field.backend_name, e.target.value)}
                  />
                )}
              </div>
            ))}
            <FormControlLabel
              control={(
                <Switch
                  checked={required}
                  onChange={(e) => setRequired(e.target.checked)}
                  size="small"
                />
            )}
              label="Required"
            />
            <Button
              className="btn btn-yellow"
              startIcon={<TbDeviceFloppy />}
              onClick={handleSaveNode}
              disabled={!isSaveEnabled}
              sx={{ mt: 2 }}
            >
              Save Node
            </Button>
          </div>
        </Modal>
      </>
    );
  }

  const registerNodes = [
    {
      type: 'start',
      name: 'start node',
      displayComponent: StartNodeDisplay,
      isStart: true,
    },
    {
      type: 'end',
      name: 'end node',
      displayComponent: EndNodeDisplay,
      isEnd: true,
    },
    {
      type: 'node',
      name: 'other node',
      displayComponent: OtherNodeDisplay,
    },
    {
      type: 'add-node',
      name: 'add node',
      displayComponent: AddNodeButton,
    },
  ];

  return (
    <>
      <div className="flow-builder-container">
        <FlowBuilder
          nodes={flowNodes}
          onChange={(newNodes) => console.log(newNodes)}
          registerNodes={registerNodes}
        />
      </div>
    </>
  );
}

// Updating prop validation
Flow.propTypes = {
  flowData: PropTypes.shape({
    welcomeMessage: PropTypes.string,
    endMessage: PropTypes.string,
    nodes: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string.isRequired,
      question: PropTypes.string.isRequired,
      type: PropTypes.string.isRequired,
      name: PropTypes.string,
      onChange: PropTypes.func.isRequired,
      required: PropTypes.bool.isRequired,
      onDelete: PropTypes.func.isRequired,
    })).isRequired,
  }).isRequired,
  onFieldChange: PropTypes.func.isRequired,
  onNodeAddition: PropTypes.func.isRequired,
  elevenlabsVoiceId: PropTypes.string.isRequired,
};

export default Flow;
