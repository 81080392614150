import React, {
  useContext, useMemo, useState, useEffect, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import {
  Button, Grid, Slider, Tooltip,
} from '@mui/material';
import {
  TbCalendarTime,
  TbFlagStar,
  TbPhone,
  TbPlayerPause,
  TbPlayerPlay, TbPlayerTrackPrev,
  TbRotateClockwise2,
  TbRouteSquare,
  TbUser,
  TbMailCheck,
  TbMailX,
  TbCheck,
} from 'react-icons/tb';
import { BiMailSend } from 'react-icons/bi';
import { Howl } from 'howler';
import {
  CONVERSATION_URL,
  CONVERSATION_AUDIO_URL,
  REPORT_MESSAGE_URL,
  DOWNLOAD_MESSAGE_AUDIO_URL,
  TRANSCRIBE_MESSAGE_URL,
  MARK_CONVERSATION_AS_DONE_URL,
  SEND_EMAIL_URL,
  CONVERSATION_LOG_URL,
  fillInIdsInUrl,
} from '../../Constants/URLS';
import './TranscriptView.scss';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import FancyHeading2 from '../../Components/FancyHeading2';
import Fact from '../../Components/Fact';
import TranscriptSegment from '../../Components/TranscriptSegment';

function TranscriptView() {
  const userContext = useContext(UserContext);
  const isSuperuser = userContext.user?.is_superuser;
  const { tokens } = userContext;
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const { conversationId } = useParams();
  const conversationUrl = fillInIdsInUrl(CONVERSATION_URL, conversationId);
  const conversationLogUrl = fillInIdsInUrl(CONVERSATION_LOG_URL, conversationId);
  const conversationAudioUrl = fillInIdsInUrl(CONVERSATION_AUDIO_URL, conversationId);
  const reportMessageUrl = fillInIdsInUrl(REPORT_MESSAGE_URL, conversationId);
  const downloadMessageAudioUrl = fillInIdsInUrl(DOWNLOAD_MESSAGE_AUDIO_URL, conversationId);
  const transcribeMessageUrl = fillInIdsInUrl(TRANSCRIBE_MESSAGE_URL, conversationId);
  const markConversationAsDoneUrl = fillInIdsInUrl(MARK_CONVERSATION_AS_DONE_URL, conversationId);
  const sendEmailUrl = fillInIdsInUrl(SEND_EMAIL_URL, conversationId);
  const [conversation, setConversation] = useState({});
  const [sprites, setSprites] = useState({});
  const playerRef = useRef(null);

  const [progressIntervalId, setProgressIntervalId] = useState(null);
  const [seek, setSeek] = useState(0);
  const playingPart = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentPosition, setCurrentPosition] = useState(0);

  function fetchData(callUrl) {
    api.get(callUrl).then((response) => {
      setConversation(response.data);
      const newSprite = {};
      newSprite.all = [0, Math.floor(response.data.duration * 1000)];
      response.data.message_set.forEach((message) => {
        newSprite[message.id] = [
          Math.floor(message.offset * 1000),
          Math.floor(message.duration * 1000),
        ];
      });
      setSprites(newSprite);
    }).catch((error) => {
      console.error('Error fetching data', error);
    });
  }

  useEffect(() => {
    fetchData(conversationUrl);
  }, [conversationUrl]);

  function capitaliseFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // START LOGIC FOR THE PLAYER (HOWL)

  const startProgressInterval = () => {
    const interval = setInterval(() => {
      if (playerRef.current && playerRef.current.playing()) {
        setSeek(playerRef.current.seek() || 0);

        if (playingPart.current) {
          const currentSprite = sprites[playingPart.current];
          if (playerRef.current.seek() * 1000 > (currentSprite[1] + currentSprite[0])) {
            playingPart.current = null;
            playerRef.current.pause();
          }
        }

        // eslint-disable-next-line
        const foundMessage = conversation.message_set.find((message) => message.offset <= playerRef.current.seek() && (message.offset + message.duration) >= playerRef.current.seek());

        if (foundMessage) {
          setCurrentPosition(conversation.message_set.indexOf(foundMessage) + 1);
        } else {
          setCurrentPosition(0);
        }
      }
    }, 100);

    return interval;
  };

  useEffect(() => {
    if (conversationAudioUrl && tokens && tokens.accessToken && Object.keys(sprites).length > 0) {
      const newPlayer = new Howl({
        src: [conversationAudioUrl],
        autoplay: false,
        format: ['mp3'],
        onplay: () => {
          setIsPlaying(true);
          clearInterval(progressIntervalId);
          const intervalId = startProgressInterval();
          setProgressIntervalId(intervalId);
        },
        onend: () => {
          setIsPlaying(false);
          clearInterval(progressIntervalId);
        },
        onpause: () => {
          setIsPlaying(false);
          clearInterval(progressIntervalId);
        },
        xhr: {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokens.accessToken}`,
          },
        },
      });
      playerRef.current = newPlayer;
    }

    return () => {
      // Cleanup logic
      clearInterval(progressIntervalId);
      // Any other cleanup if needed
    };
  }, [conversationAudioUrl, tokens, sprites]);

  const handlePlay = () => {
    if (isPlaying) return;
    playerRef.current.play();
  };

  const handlePause = () => {
    playerRef.current.pause();
  };

  const playPart = (id) => {
    playerRef.current.stop();
    playingPart.current = id;
    playerRef.current.seek(sprites[id][0] / 1000);
    playerRef.current.play();
  };

  const playFromBeginning = () => {
    playerRef.current.stop();
    playerRef.current.seek(0);
    setTimeout(() => playerRef.current.play(), 100);
  };

  const handleMoveSlider = (event, newValue) => {
    playerRef.current.seek(newValue);
    setSeek(newValue);
  };

  const reportMessage = (id) => {
    api.post(reportMessageUrl, { message_id: id })
      .then(() => {
        fetchData(conversationUrl);
      })
      .catch((error) => {
        console.error('Error reporting message', error);
      });
  };

  const downloadAudio = (id) => {
    const headers = {
      Authorization: `Bearer ${tokens.accessToken}`,
    };
    const downloadUrl = `${downloadMessageAudioUrl}?message_id=${id}`;
    fetch(downloadUrl, { headers })
      .then((response) => {
        if (response.ok) return response.blob();
        throw new Error('Network response was not ok.');
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `message-${id}.mp3`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(link);
      })
      .catch((error) => console.error('Download error:', error));
  };

  const transcribeAudio = (id) => {
    const headers = {
      Authorization: `Bearer ${tokens.accessToken}`,
    };
    const data = { message_id: id };
    const transcribeUrl = `${transcribeMessageUrl}?message_id=${id}`;
    api.post(transcribeUrl, data, headers).then((response) => {
      alert(response.data.transcription);
    }).catch((error) => {
      console.error('Error fetching data', error);
    });
  };

  const markAsDone = (id) => {
    console.log('Marking as done', id);
    const headers = {
      Authorization: `Bearer ${tokens.accessToken}`,
    };
    api.post(markConversationAsDoneUrl, {}, headers).then(() => {
      fetchData(conversationUrl);
    }).catch((error) => {
      console.error('Error marking as done', error);
    });
  };

  const sendEmail = (id) => {
    console.log('Sending email', id);
    const headers = {
      Authorization: `Bearer ${tokens.accessToken}`,
    };
    api.post(sendEmailUrl, {}, headers).then(() => {
      fetchData(conversationUrl);
    }).catch((error) => {
      console.error('Error sending email', error);
    });
  };

  // END LOGIC FOR THE PLAYER (HOWL)

  return (
    <div>
      {Object.keys(conversation).length === 0 ? (
        <p>No data for selected conversation.</p>
      ) : (
        <>
          <FancyHeading2 text1="Transcript for" text2={`Conversation #${conversation.numerical_id}`} />
          <Grid container spacing={6}>
            <Grid item xs={12} md={5}>
              <div className="box">
                <Fact
                  icon={<TbCalendarTime />}
                  title="Created at"
                  value={new Date(conversation.created_at).toLocaleString()}
                />
                <Fact
                  icon={<TbUser />}
                  title="Caller Name"
                  value={conversation.caller.name || 'Unknown'}
                />
                <Fact
                  icon={<TbPhone />}
                  title="Phone Number"
                  value={conversation.caller.phone_number}
                />
                <Fact
                  icon={<TbRotateClockwise2 />}
                  title="Duration"
                  value={`${conversation.duration} seconds`}
                />
                <Fact
                  icon={<TbFlagStar />}
                  title="Status"
                  value={capitaliseFirstLetter(conversation.status)}
                />
                <Fact
                  icon={<TbRouteSquare />}
                  title="Flow"
                  value={conversation.flow_name || 'Unknown'}
                />
                <Fact
                  icon={conversation.email_sent ? <TbMailCheck /> : <TbMailX />}
                  title="Mail Sent"
                  value={conversation.email_sent ? 'Yes' : 'No'}
                  noMargin
                />
              </div>
              { isSuperuser && (
                <div className="box actions">
                  <Button
                    onClick={() => {
                      window.location.href = conversationLogUrl;
                    }}
                    className="btn btn-yellow left"
                    startIcon={<TbCheck />}
                  >
                    Logs
                  </Button>
                </div>
              )}
              {conversation.status === 'active'
                  && isSuperuser && (
                  <div className="box actions">
                      {!conversation.email_sent && (
                        <Button
                          onClick={() => sendEmail(conversation.id)}
                          className="btn btn-yellow left"
                          startIcon={<BiMailSend />}
                        >
                          Send Email
                        </Button>
                      )}

                    <Button
                      onClick={() => markAsDone(conversation.id)}
                      className={`btn btn-yellow ${conversation.email_sent ? 'center' : 'right'}`}
                      startIcon={<TbCheck />}
                    >
                      Mark Done
                    </Button>
                  </div>
              )}
              <div className="box player">
                Currently playing
                {' '}
                <b>
                  {currentPosition > 0 ? `message #${currentPosition}` : 'entire conversation'}
                </b>
                <Slider
                  min={0}
                  max={playerRef.current ? playerRef.current.duration() : 0}
                  value={seek}
                  step={0.00001}
                  onChange={handleMoveSlider}
                  onMouseDown={handlePause}
                  onMouseUp={handlePlay}
                  className="player-slider"
                />
                <Tooltip title={isPlaying ? 'Pause' : 'Play'} placement="top" arrow>
                  <span>
                    <Button
                      onClick={isPlaying ? handlePause : handlePlay}
                      disabled={!playerRef.current}
                      className={`btn btn-${isPlaying ? 'red' : 'yellow'} btn-icon`}
                    >
                      {isPlaying ? <TbPlayerPause /> : <TbPlayerPlay />}
                    </Button>
                  </span>
                </Tooltip>
                <Tooltip title="Play from beginning" placement="top" arrow>
                  <span>
                    <Button
                      onClick={playFromBeginning}
                      disabled={!playerRef.current}
                      className="btn btn-yellow btn-icon"
                      sx={{ ml: 2 }}
                    >
                      <TbPlayerTrackPrev />
                    </Button>
                  </span>
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={12} md={7}>
              <div className="transcript-wrapper">
                <div className="transcript">
                  {conversation.message_set.map((message, index) => (
                    <TranscriptSegment
                      key={index}
                      index={index}
                      message={message}
                      border={message.type === conversation.message_set[index - 1]?.type}
                      playPart={() => playPart(message.id)}
                      playing={isPlaying && sprites[message.id][0] <= seek * 1000
                      && sprites[message.id][1] + sprites[message.id][0] >= seek * 1000}
                      reportMessage={() => reportMessage(message.id)}
                      downloadAudio={() => downloadAudio(message.id)}
                      transcribeAudio={() => transcribeAudio(message.id)}
                      isSuperuser={isSuperuser}
                    />
                  ))}
                </div>
              </div>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
}

export default TranscriptView;
