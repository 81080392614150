import React from 'react';
import './AccessManagementView.scss';
import UserManagementContext, { UserManagementProvider } from './UserManagementContext';
import ActiveProviders from './ActiveProviders';
import UserTable from './UserTable';

function AccessManagementView() {
  return (
    <UserManagementProvider>
      <div className="user-management-view">
        <UserManagementContext.Consumer>
          {({ activeProviders, users }) => (
            <>
              <ActiveProviders providers={activeProviders} />
              <UserTable users={users} />
            </>
          )}
        </UserManagementContext.Consumer>
      </div>
    </UserManagementProvider>
  );
}

export default AccessManagementView;
