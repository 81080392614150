import { updateNode } from './flowNodeActions';

export const initialState = {
  voices: [],
  activeFlow: null,
  flowData: null,
};

export const actionTypes = {
  SET_VOICES: 'SET_VOICES',
  SET_ACTIVE_FLOW: 'SET_ACTIVE_FLOW',
  SET_FLOW_DATA: 'SET_FLOW_DATA',
  UPDATE_NODE_DETAILS: 'UPDATE_NODE_DETAILS',
  UPDATE_FLOW_DETAILS: 'UPDATE_FLOW_DETAILS',
  ADD_NODE: 'ADD_NODE',
  DELETE_NODE: 'DELETE_NODE',
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET_VOICES:
      return { ...state, voices: action.payload };
    case actionTypes.SET_ACTIVE_FLOW:
      return { ...state, activeFlow: action.payload };
    case actionTypes.SET_FLOW_DATA:
      return { ...state, flowData: action.payload };
    case actionTypes.UPDATE_NODE_DETAILS:
    {
      const updatedNodes = state.flowData.nodes.map((node) => {
        if (node.id === action.payload.nodeId) {
          return { ...node, ...action.payload.nodeData };
        }
        return node;
      });
      updateNode(action.payload.nodeId, state.activeFlow.id, action.payload.nodeData);
      return { ...state, flowData: { ...state.flowData, nodes: updatedNodes } };
    }
    case actionTypes.UPDATE_FLOW_DETAILS:
    {
      return { ...state, [action.payload.fieldName]: action.payload.value };
    }
    case actionTypes.ADD_NODE:
    {
      const { addedNode, handleNodeChange, handleNodeDeletion } = action.payload;
      const newNode = {
        ...addedNode,
        name: addedNode.node_name,
        type: addedNode.node_type,
        onChange: handleNodeChange,
        onDelete: handleNodeDeletion,
      };
      delete newNode.node_name;

      const newNodes = [...state.flowData.nodes, newNode];
      const updatedFlowData = { ...state.flowData, nodes: newNodes };

      return {
        ...state,
        flowData: updatedFlowData,
      };
    }
    case actionTypes.DELETE_NODE:
    {
      const { nodeId } = action.payload;
      const newNodes = state.flowData.nodes.filter((node) => node.id !== nodeId);
      const updatedFlowData = { ...state.flowData, nodes: newNodes };
      return {
        ...state,
        flowData: updatedFlowData,
      };
    }
    default:
      return state;
  }
}
