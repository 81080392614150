import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Container } from '@mui/material';

import './App.scss';
import { NotificationContainer } from 'react-notifications';
import history from './Utils/history';
import Pages from './Constants/pages';
import UserProvider from './Providers/UserProvider/UserProvider';
import NotificationProvider from './Providers/NotificationProvider/NotificationProvider';
import CustomRouter from './Utils/CustomRouters';
import AuthenticatedRoute from './Components/AuthenticatedRoute/AuthenticatedRoute';
import NavigationBar from './Components/NavigationBar';

function App() {
  const pages = Pages();

  return (
    <UserProvider>
      <CustomRouter history={history}>
        <div className="App">
          <NotificationProvider>
            <>
              <NotificationContainer />
              <NavigationBar pages={pages} />
              <Container sx={{ mt: 6, mb: 9 }}>
                <Routes>
                  {Object.keys(pages).map((key, idx) => (
                    <Route
                      key={idx}
                      path={pages[key].path}
                      element={pages[key].loginNeeded ? (
                        <AuthenticatedRoute
                          element={pages[key].element}
                          roleNeeded={pages[key].roleNeeded}
                        />
                      ) : pages[key].element}
                    />
                  ))}
                </Routes>
              </Container>
            </>
          </NotificationProvider>
        </div>
      </CustomRouter>
    </UserProvider>
  );
}

export default App;
