import { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

function AudioHandler({
  isRecording, wsUrl, language, selectedFlow, onConversationEnd,
}) {
  const socketRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const isPlayingRef = useRef(isPlaying);
  const audioContextRef = useRef(new AudioContext());
  const audioQueueRef = useRef([]);
  const MINIMUM_BUFFER_SIZE = 2;
  const TIMEOUT = 250; // Timeout in milliseconds
  const BUFFER_TIME = 1500; // Buffer time in milliseconds
  const timeoutId = useRef(null);

  const playNextAudio = () => {
    clearTimeout(timeoutId.current); // Clear the timeout since we're starting playback
    if (audioQueueRef.current.length > 0) {
      setIsPlaying(true);
      isPlayingRef.current = true;
      const audioBuffer = audioQueueRef.current.shift();
      const source = audioContextRef.current.createBufferSource();
      source.buffer = audioBuffer;
      source.connect(audioContextRef.current.destination);
      source.start();
      source.onended = () => {
        if (audioQueueRef.current.length > 0) {
          playNextAudio();
        } else {
          setIsPlaying(false);
          isPlayingRef.current = false;
        }
      };
    } else {
      setIsPlaying(false);
      isPlayingRef.current = false;
    }
  };
  useEffect(() => {
    if (isRecording) {
      const newSocket = new WebSocket(`${wsUrl}${selectedFlow}/?token=${localStorage.getItem('frontend_access_token')}&language=${language}`);

      socketRef.current = newSocket;
      newSocket.onmessage = async (event) => {
        if (event.data instanceof Blob) {
          const audioDataItem = await event.data.arrayBuffer();
          console.log(`[${new Date().toISOString()}] Received audio data:`);
          audioContextRef.current.decodeAudioData(audioDataItem)
            .then((audioBuffer) => {
              audioQueueRef.current.push(audioBuffer);
              if (!isPlayingRef.current && audioQueueRef.current.length >= MINIMUM_BUFFER_SIZE) {
                playNextAudio();
              }
            })
            .catch((error) => {
              console.error('Failed to decode audio:', error);
            });
        } else {
          const message = JSON.parse(event.data);

          if (message.data === 'stop_audio') {
            audioQueueRef.current = [];
            setIsPlaying(false);
            isPlayingRef.current = false;
          } else if (message.data === 'continue_audio') {
            playNextAudio();
          }
        }
        // Set up the timeout to play audio if MINIMUM_BUFFER_SIZE is not reached
        if (!isPlayingRef.current) {
          clearTimeout(timeoutId.current); // Clear any existing timeout
          timeoutId.current = setTimeout(() => {
            if (audioQueueRef.current.length > 0 && !isPlayingRef.current) {
              playNextAudio();
            }
          }, TIMEOUT);
        }
      };
      newSocket.onopen = async () => {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            sampleRate: 44100,
            channelCount: 1,
          },
        });
        const options = {
          mimeType: 'audio/webm',
          audioBitsPerSecond: 96000,
        };
        mediaRecorderRef.current = new MediaRecorder(stream, options);
        mediaRecorderRef.current.addEventListener('dataavailable', (event) => {
          console.log(`[${new Date().toISOString()}] sending audio data:`);
          if (socketRef.current.readyState === WebSocket.OPEN) {
            socketRef.current.send(event.data);
          }
        });
        mediaRecorderRef.current.start(100);
      };
      newSocket.onclose = () => {
        // eslint-disable-next-line
        const calculateTotalDuration = (audioQueue) => {
          // eslint-disable-next-line
          return audioQueue.reduce((totalDuration, audioBuffer) => {
            return totalDuration + audioBuffer.duration;
          }, 0);
        };
        if (isPlayingRef.current || audioQueueRef.current.length > 0) {
          const remainingDuration = calculateTotalDuration(audioQueueRef.current) * 1000;
          setTimeout(() => {
            onConversationEnd();
          }, remainingDuration + BUFFER_TIME);
        } else {
          onConversationEnd();
        }
      };
    } else {
      if (mediaRecorderRef.current) {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
      }
      if (socketRef.current) {
        socketRef.current.close();
      }
    }
  }, [isRecording]);
  useEffect(() => {
    isPlayingRef.current = isPlaying;
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify({ isPlaying }));
    }
  }, [isPlaying]);
  return null;
}
AudioHandler.propTypes = {
  isRecording: PropTypes.bool.isRequired,
  wsUrl: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  selectedFlow: PropTypes.string.isRequired,
  onConversationEnd: PropTypes.func.isRequired,
};
export default AudioHandler;
