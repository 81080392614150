import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@mui/material';
import { MdOutlineCreate } from 'react-icons/md';
import ActiveProviderCard from './ActiveProviderCard';
import ProviderModal from './ProviderModal';
import UserManagementContext from './UserManagementContext';
import FancyHeading2 from '../../Components/FancyHeading2';

function ActiveProviders({ providers }) {
  const [editingProvider, setEditingProvider] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const { deleteProvider } = useContext(UserManagementContext);

  const handleEdit = (provider) => {
    setEditingProvider(provider);
    setIsEdit(true);
    setIsModalOpen(true);
  };

  const handleAdd = () => {
    setEditingProvider(null);
    setIsEdit(false);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingProvider(null);
  };

  return (
    <section className="active-providers">
      <FancyHeading2 text1="Access Management" text2="Active Providers" />
      <Button
        className="btn btn-yellow"
        onClick={handleAdd}
        startIcon={<MdOutlineCreate />}
      >
        Add New Provider
      </Button>
      <Grid container spacing={2} style={{ marginTop: '16px' }}>
        {providers.map((provider) => (
          <Grid item key={provider.id}>
            <ActiveProviderCard
              provider={provider}
              onEdit={handleEdit}
              onDelete={() => deleteProvider(provider)}
            />
          </Grid>
        ))}
      </Grid>
      <ProviderModal
        open={isModalOpen}
        provider={editingProvider}
        onClose={handleCloseModal}
        isEdit={isEdit}
      />
    </section>
  );
}

ActiveProviders.propTypes = {
  providers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      provider: PropTypes.string.isRequired,
      provider_id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      client_id: PropTypes.string.isRequired,
      key: PropTypes.string,
      // eslint-disable-next-line react/forbid-prop-types
      settings: PropTypes.object,
    }),
  ).isRequired,
};

export default ActiveProviders;
