import React from 'react';
import PropTypes from 'prop-types';

function Fact({
  icon, title, value, noMargin,
}) {
  return (
    <div className="fact" style={noMargin ? { marginBottom: 0 } : {}}>
      <div className="fact-icon">
        {icon}
      </div>
      <div className="fact-text">
        <h4>{value}</h4>
        <p>{title}</p>
      </div>
    </div>
  );
}

// Define PropTypes for Fact
Fact.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  noMargin: PropTypes.bool,
};

Fact.defaultProps = {
  noMargin: false,
};

export default Fact;
