import React, { useContext, useEffect, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import Swal from 'sweetalert2';

import Flow from '../../Components/FlowBuilderComponents/Flow';
import FlowDetailSideBar from '../../Components/FlowDetailSideBar/FlowDetailSideBar';
import { fetchElevenLabsVoices } from './flowNodeAPI';
import { actionTypes, initialState, reducer } from './flowBuilderReducer';
import {
  addNodeAction, deleteNodeAction, updateFlow, fetchFlow,
} from './flowNodeActions';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { PHONE_NUMBERS_URL } from '../../Constants/URLS';
import apiUtils from '../../Utils/ApiUtils';
import FancyHeading2 from '../../Components/FancyHeading2';

function FlowBuilderView() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { flowId } = useParams();
  const { voices, activeFlow, flowData } = state;
  const { getConfig, refresh, logout } = useContext(UserContext);
  const api = apiUtils();

  const handleVoiceChange = (voiceId) => {
    const updatedFlow = { ...activeFlow, elevenlabs_voice: voiceId };
    dispatch({ type: actionTypes.UPDATE_FLOW_DETAILS, payload: { elevenlabs_voice: voiceId } });
    updateFlow(activeFlow.id, { elevenlabs_voice: voiceId });
    dispatch({ type: actionTypes.SET_ACTIVE_FLOW, payload: updatedFlow });
  };

  const getVoiceNameById = (voiceId) => {
    const voice = voices.find((v) => v.id === voiceId);
    return voice ? voice.name : 'No Voice Selected';
  };

  const getFlowName = () => (activeFlow ? activeFlow.name : 'No Flow Selected');

  const handleNodeChange = (nodeId, nodeData) => {
    dispatch({
      type: actionTypes.UPDATE_NODE_DETAILS,
      payload: { nodeId, nodeData },
    });
  };

  const handleNodeDeletion = async (nodeId) => {
    const response = await deleteNodeAction(nodeId);
    if (response) {
      dispatch({
        type: actionTypes.DELETE_NODE,
        payload: { nodeId },
      });
    }
  };

  const constructFlowData = (flow) => {
    const flowNodes = flow.nodes_in_order.map((node) => ({
      ...node,
      id: node.id,
      question: node.question,
      name: node.node_name,
      type: node.node_type,
      required: node.required,
    }));

    return {
      welcomeMessage: flow.welcome_message,
      endMessage: flow.end_message,
      nodes: flowNodes.map((node) => ({
        ...node,
        onChange: handleNodeChange,
        onDelete: handleNodeDeletion,
      })),
    };
  };

  const refreshFlowData = async () => {
    const refreshedFlow = await fetchFlow(flowId, getConfig, refresh, logout);
    if (refreshedFlow) {
      const newFlowData = constructFlowData(refreshedFlow);
      dispatch({ type: actionTypes.SET_ACTIVE_FLOW, payload: refreshedFlow });
      dispatch({ type: actionTypes.SET_FLOW_DATA, payload: newFlowData });
    }
    return refreshedFlow;
  };

  const handleFlowDetailChange = async (name, value) => {
    let fieldName = name;
    dispatch({
      type: actionTypes.UPDATE_FLOW_DETAILS,
      payload: { fieldName, value },
    });
    const response = await updateFlow(activeFlow.id, { [fieldName]: value });

    if (response) {
      await refreshFlowData();
    }

    if (name === 'welcome_message') {
      fieldName = 'welcomeMessage';
    } else if (name === 'end_message') {
      fieldName = 'endMessage';
    }

    dispatch({
      type: actionTypes.SET_FLOW_DATA,
      payload: {
        ...flowData,
        [fieldName]: value,
      },
    });
  };

  const addPhoneNumber = async () => {
    const newPhoneNumber = { provider: 'Vonage', country_code: 'NL' };

    try {
      const response = await api.post(PHONE_NUMBERS_URL, newPhoneNumber);
      return response.data.phone_number;
    } catch (error) {
      console.error('Error adding a new phone number:', error);
      throw error;
    }
  };

  const handleActivateClick = async () => {
    await handleFlowDetailChange('active', !activeFlow.active);
    const refreshedFlow = await refreshFlowData();

    if (refreshedFlow.active && !refreshedFlow.phone_number) {
      const result = await Swal.fire({
        title: 'No Phone Number',
        text: 'There is no phone number associated with this flow. Do you want to create a new number?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
      });

      if (result.isConfirmed) {
        const newPhoneNumber = await addPhoneNumber();
        await handleFlowDetailChange('phone_number', newPhoneNumber);
        await refreshFlowData();
      }
    }
  };

  const handleNodeAddition = async (lastNodeId, question, name, type, required, extraFields) => {
    // eslint-disable-next-line max-len
    const addedNode = await addNodeAction(lastNodeId, question, name, type, required, extraFields, activeFlow.id);
    if (addedNode) {
      dispatch({
        type: actionTypes.ADD_NODE,
        payload: { addedNode, handleNodeChange, handleNodeDeletion },
      });
    } else {
      console.error('Failed to add node');
    }
  };

  useEffect(() => {
    const initializeFlowData = async () => {
      const response = await fetchFlow(flowId, getConfig, refresh, logout);
      if (response) {
        const newFlowData = constructFlowData(response);
        dispatch({ type: actionTypes.SET_ACTIVE_FLOW, payload: response });
        dispatch({ type: actionTypes.SET_FLOW_DATA, payload: newFlowData });
      }
    };

    const initializeVoices = async () => {
      const fetchedVoices = await fetchElevenLabsVoices();
      dispatch({ type: actionTypes.SET_VOICES, payload: fetchedVoices });
    };

    initializeFlowData();
    initializeVoices();
  }, [flowId]);

  return (
    <div className="flow-builder-container">
      <FancyHeading2 text1="Flow Builder" text2={getFlowName()} />
      <div>
        {flowData && (
          <Grid container spacing={4} sx={{ mt: 1 }}>
            <Grid item xs={12} md={4}>
              <FlowDetailSideBar
                voices={voices}
                activeFlow={activeFlow}
                getVoiceNameById={getVoiceNameById}
                handleVoiceChange={handleVoiceChange}
                handleFlowDetailChange={handleFlowDetailChange}
                handleActivateClick={handleActivateClick}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <Flow
                flowData={flowData}
                onFieldChange={handleFlowDetailChange}
                onNodeAddition={handleNodeAddition}
                elevenlabsVoiceId={activeFlow.elevenlabs_voice}
              />
            </Grid>
          </Grid>
        )}
      </div>
    </div>
  );
}

export default FlowBuilderView;
