import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Select,
  MenuItem,
  OutlinedInput,
  Chip,
} from '@mui/material';
import UserManagementContext from './UserManagementContext';
import './UserTable.scss';
import FancyHeading2 from '../../Components/FancyHeading2';

function UserTable({ users }) {
  const { permissionGroups, updateUserPermissionGroups } = useContext(UserManagementContext);

  const handlePermissionChange = (userId, event) => {
    const {
      target: { value },
    } = event;
    // Update user permission groups
    updateUserPermissionGroups(userId, typeof value === 'string' ? value.split(',') : value);
  };

  const getGroupNameById = (id) => {
    const group = permissionGroups.find((g) => g.id === id);
    return group ? group.name : 'Unknown Group';
  };

  return (
    <section>
      <FancyHeading2 text1="Access Management" text2="Users" />
      <TableContainer className="styled-table-container">
        <Table className="styled-table">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Username</TableCell>
              <TableCell>Superuser</TableCell>
              <TableCell>Permissions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((user) => (
              <TableRow key={user.id}>
                <TableCell><strong>{user.id}</strong></TableCell>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>
                  <Chip label={user.is_superuser ? 'Yes' : 'No'} color={user.is_superuser ? 'success' : 'error'} />
                </TableCell>
                <TableCell>
                  <Select
                    multiple
                    value={user.groups || []}
                    onChange={(event) => handlePermissionChange(user.id, event)}
                    input={<OutlinedInput label="Permissions" />}
                    renderValue={(selected) => selected.map((id) => getGroupNameById(id)).join(', ')}
                    className="select-chip"
                  >
                    {permissionGroups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
}

UserTable.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      email: PropTypes.string.isRequired,
      username: PropTypes.string.isRequired,
      groups: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default UserTable;
