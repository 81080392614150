import React from 'react';
import PhoneNumbersTable from '../../Components/PhoneNumbersTable';
import FlowTable from '../../Components/FlowTable';

function FlowOverview() {
  return (
    <>
      <FlowTable />
      <PhoneNumbersTable />
    </>
  );
}

export default FlowOverview;
