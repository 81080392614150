import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  TextField,
  Dialog,
  Chip,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { MdOutlineCreate } from 'react-icons/md';
import { TbArrowRight, TbTrash } from 'react-icons/tb';
import Swal from 'sweetalert2';
import FancyHeading from '../FancyHeading';
import apiUtils from '../../Utils/ApiUtils';
import { CONVERSATIONAL_FLOW_LIST } from '../../Constants/URLS';
import { createFlowAction, deleteFlowAction } from '../../Views/FlowBuilderView/flowNodeActions';
import './FlowTable.scss';

function FlowsTable() {
  const [flows, setFlows] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = apiUtils();
  const navigate = useNavigate();
  const [newFlowDialog, setNewFlowDialog] = useState(false);
  const [newFlowName, setNewFlowName] = useState('');

  const fetchFlows = () => {
    setLoading(true);
    api.get(CONVERSATIONAL_FLOW_LIST)
      .then((response) => {
        setFlows(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching flows:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchFlows();
  }, []);

  const addFlow = async () => {
    if (!newFlowName) {
      return;
    }
    try {
      const newFlow = await createFlowAction(newFlowName);
      console.log('New Flow:', newFlow);
      fetchFlows();
      setNewFlowDialog(false);
      setNewFlowName('');
    } catch (error) {
      console.error('Error creating flow:', error);
    }
  };

  const deleteFlow = async (id, flowName) => {
    const alertText = `Are you sure you want to delete the flow: ${flowName}?`;
    Swal.fire({
      icon: 'warning',
      title: 'Are you sure?',
      text: alertText,
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deletionResult = await deleteFlowAction(id);
        if (deletionResult) {
          Swal.fire({
            icon: 'success',
            title: 'Flow deleted successfully!',
          });
          fetchFlows();
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Failed to delete flow',
          });
        }
      }
    });
  };

  // eslint-disable-next-line react/prop-types
  function StatusChip({ active }) {
    return <Chip label={active ? 'Yes' : 'No'} color={active ? 'success' : 'error'} />;
  }

  return (
    <div>
      <FancyHeading text="Flows" />
      <Button
        className="btn btn-yellow"
        startIcon={<MdOutlineCreate />}
        color="primary"
        onClick={() => setNewFlowDialog(true)}
      >
        Create Flow
      </Button>

      <Dialog open={newFlowDialog} onClose={() => setNewFlowDialog(false)}>
        <div className="dialog-content">
          <h3 className="dialog-title">Create New Flow</h3>
          <TextField
            label="Flow Name"
            value={newFlowName}
            onChange={(e) => setNewFlowName(e.target.value)}
            fullWidth
            margin="normal"
          />
          <Button
            type="button"
            onClick={addFlow}
            className="btn btn-yellow"
            startIcon={<MdOutlineCreate />}
            sx={{ mt: 2 }}
          >
            Create
          </Button>
        </div>
      </Dialog>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Created At</TableCell>
                <TableCell>Updated At</TableCell>
                <TableCell>Active</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {flows.map((flow) => (
                <TableRow key={flow.id}>
                  <TableCell><strong>{flow.name}</strong></TableCell>
                  <TableCell>{new Date(flow.created_at).toLocaleString()}</TableCell>
                  <TableCell>{new Date(flow.updated_at).toLocaleString()}</TableCell>
                  <TableCell><StatusChip active={flow.active} /></TableCell>
                  <TableCell align="right">
                    <Button
                      className="btn btn-red"
                      color="secondary"
                      startIcon={<TbTrash />}
                      onClick={() => deleteFlow(flow.id, flow.name)}
                    >
                      Delete
                    </Button>
                    <Button
                      className="btn btn-yellow"
                      color="primary"
                      endIcon={<TbArrowRight />}
                      onClick={() => {
                        navigate(`/flowbuilder/${flow.id}`);
                      }}
                    >
                      Edit
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default FlowsTable;
