import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { GoogleLogin, GoogleOAuthProvider } from '@react-oauth/google';
import { PublicClientApplication } from '@azure/msal-browser';
import PropTypes from 'prop-types';
import apiUtils from '../../Utils/ApiUtils';
import { GOOGLE_LOGIN_URL, LOGIN_PROVIDERS_URL, MICROSOFT_LOGIN_URL } from '../../Constants/URLS';

export const loginRequest = {
  scopes: ['User.Read', 'Directory.Read.All'],
};

function SocialLogin({ loginSocial }) {
  const [providers, setProviders] = useState([]);
  const api = apiUtils();

  useEffect(() => {
    api.getNoAuth(LOGIN_PROVIDERS_URL)
      .then((response) => {
        setProviders(response.data);
      })
      .catch((error) => {
        console.error('Failed to fetch social login providers', error);
      });
  }, []);

  return (
    <div className="social-login-buttons">
      {providers.map((provider) => {
        if (provider.provider === 'google') {
          console.log(provider.client_id);
          return (
            <GoogleOAuthProvider clientId={provider.client_id}>
              <GoogleLogin
                onSuccess={
                                    (response) => {
                                      loginSocial(GOOGLE_LOGIN_URL, response.credential);
                                    }
                                }
                onFailure={(response) => {
                  console.log(response);
                }}
              />
            </GoogleOAuthProvider>
          );
        }
        if (provider.provider === 'microsoft') {
          const msalInstance = new PublicClientApplication({
            auth: {
              clientId: provider.client_id,
              authority: `https://login.microsoftonline.com/${provider.settings.tenant_id}`,
              redirectUri: 'http://localhost:3000',
            },
          });
          const handleLogin = async () => {
            try {
              await msalInstance.initialize();
              msalInstance.loginPopup(loginRequest).then((response) => {
                loginSocial(MICROSOFT_LOGIN_URL, response.accessToken);
              });
            } catch (e) {
              console.log(e);
            }
          };
          return (
            <Button
              key={provider.id}
              className={`btn btn-${provider.provider}`}
              onClick={() => {
                handleLogin();
              }}
            >
              {provider.name}
            </Button>
          );
        }
        return (
          <>
            <Button
              key={provider.id}
              className={`btn btn-${provider.provider}`}
            >
              {provider.name}
            </Button>
          </>
        );
      })}

    </div>
  );
}

SocialLogin.propTypes = {
  loginSocial: PropTypes.func.isRequired,
};

export default SocialLogin;
