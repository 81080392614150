import React, {
  useEffect, useState, useContext, useMemo,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Box,
  Button,
  Chip, Drawer,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  TbAdjustments,
  TbArrowRight, TbCalendarTime, TbTrash,
} from 'react-icons/tb';
import Swal from 'sweetalert2';
import dayjs from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { CONVERSATION_LIST } from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import './ConversationsTableView.scss';
import FancyHeading from '../../Components/FancyHeading';
import NToggleChip from '../../Components/NToggleChip/NToggleChip';
import { fetchFlows } from '../FlowBuilderView/flowNodeAPI';

// eslint-disable-next-line react/prop-types
function StatusChip({ status }) {
  let color = 'info';
  switch (status) {
    case 'done':
      color = 'success';
      break;
    case 'hung_up':
      color = 'warning';
      break;
    case 'active':
      color = 'primary';
      break;
    default:
      color = 'info';
  }
  return <Chip label={status} color={color} />;
}

// eslint-disable-next-line react/prop-types
function EmailStatusChip({ emailSent }) {
  if (emailSent) {
    return <Chip label="Sent" color="success" />;
  }

  return <Chip label="Not Sent" color="error" />;
}

const useInitialQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Utility to get a parameter with fallback and custom parsing
  const getParam = (paramName, parseFn = (value) => value, defaultValue = null) => {
    const value = searchParams.get(paramName);
    return value ? parseFn(value) : defaultValue;
  };

  // Extract and parse necessary parameters
  const page = getParam('page', parseInt, 1);
  const status = getParam('status', String);
  const emailSent = getParam('email_sent', String);
  const createdAtGte = getParam('created_at__gte', dayjs);
  const createdAtLte = getParam('created_at__lte', dayjs);
  const flow = getParam('flow', String);
  const durationGte = getParam('duration__gte', String);
  const durationLte = getParam('duration__lte', String);
  const ordering = searchParams.get('ordering');
  // eslint-disable-next-line no-nested-ternary
  const sortByDuration = ordering?.includes('duration') ? (ordering.includes('-') ? -1 : 1) : 0;
  // eslint-disable-next-line no-nested-ternary
  const sortByTimestamp = ordering?.includes('created_at') ? (ordering.includes('-') ? -1 : 1) : 0;

  return {
    page,
    status,
    emailSent,
    createdAtGte,
    createdAtLte,
    flow,
    durationGte,
    durationLte,
    sortByDuration,
    sortByTimestamp,
    setSearchParams,
  };
};

function ConversationsTableView() {
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);
  const navigate = useNavigate();

  const {
    page: initialPage,
    status: initialStatus,
    emailSent: initialEmailStatus,
    createdAtGte: initialCreatedAfter,
    createdAtLte: initialCreatedBefore,
    flow: initialFlow,
    durationGte: initialDurationMin,
    durationLte: initialDurationMax,
    sortByDuration: initialSortByDuration,
    sortByTimestamp: initialSortByTimestamp,
    setSearchParams,
  } = useInitialQueryParams();

  const [conversations, setConversations] = useState([]);
  const [pages, setPages] = useState(0);
  const [page, setPage] = useState(initialPage);
  const [flows, setFlows] = useState([]);
  const [startDateTime, setStartDateTime] = useState(initialCreatedAfter);
  const [endDateTime, setEndDateTime] = useState(initialCreatedBefore);
  const [convStatus, setConvStatus] = useState(initialStatus);
  const [emailStatus, setEmailStatus] = useState(initialEmailStatus);
  const [sortByDuration, setSortByDuration] = useState(initialSortByDuration);
  const [filtersDrawer, setFiltersDrawer] = useState({
    open: false,
    initialStatus,
    initialEmailStatus,
    initialCreatedAfter,
    initialCreatedBefore,
    initialFlow,
    initialDurationMin,
    initialDurationMax,
  });
  const [sortByTimestamp, setSortByTimestamp] = useState(initialSortByTimestamp);
  const [filterFlow, setFilterFlow] = useState(initialFlow);
  const [durationMin, setDurationMin] = useState(initialDurationMin);
  const [durationMax, setDurationMax] = useState(initialDurationMax);

  const fetchConversations = (resetPage = false) => {
    const fetchPage = resetPage ? 1 : page;
    let url = `${CONVERSATION_LIST}?page=${fetchPage}`;
    if (startDateTime) url += `&created_at__gte=${dayjs(startDateTime).format('YYYY-MM-DDTHH:mm:ss')}`;
    if (endDateTime) url += `&created_at__lte=${dayjs(endDateTime).format('YYYY-MM-DDTHH:mm:ss')}`;
    if (convStatus) url += `&status=${convStatus}`;
    if (emailStatus) url += `&email_sent=${emailStatus}`;
    if (sortByDuration > 0 || sortByTimestamp > 0) url += '&ordering=';
    if (sortByDuration > 0) url += `,duration${sortByDuration === 1 ? '' : '-'}`;
    if (sortByTimestamp > 0) url += `,created_at${sortByTimestamp === 1 ? '' : '-'}`;
    if (filterFlow) url += `&flow=${filterFlow}`;
    if (durationMin) url += `&duration__gte=${durationMin}`;
    if (durationMax) url += `&duration__lte=${durationMax}`;

    api.get(url)
      .then((response) => {
        setConversations(response.data.results);

        const resultsPerPage = 15;
        const totalPages = Math.ceil(response.data.count / resultsPerPage);
        setPages(totalPages);

        // If the current page is greater than the total pages, reset to the last page
        if (page > totalPages && totalPages > 0) {
          setSearchParams({
            page: totalPages,
            status: convStatus || '',
            email_sent: emailStatus || '',
            created_at__gte: startDateTime ? dayjs(startDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            created_at__lte: endDateTime ? dayjs(endDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            flow: filterFlow || '',
            duration__gte: durationMin || '',
            duration__lte: durationMax || '',
            // eslint-disable-next-line no-nested-ternary
            ordering: sortByDuration ? `duration${sortByDuration === 1 ? '' : '-'}` : sortByTimestamp ? `created_at${sortByTimestamp === 1 ? '' : '-'}` : '',
          });
          setPage(totalPages);
        }
      })
      .catch((error) => {
        console.error('Error fetching conversations', error);
        // If the error is due to an invalid page, reset to page 1 and fetch again
        if (error.response && (error.response.status === 404 || error.response.status === 400)) {
          setPage(1);
          setSearchParams({
            page: 1,
            status: convStatus || '',
            email_sent: emailStatus || '',
            created_at__gte: startDateTime ? dayjs(startDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            created_at__lte: endDateTime ? dayjs(endDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
            flow: filterFlow || '',
            duration__gte: durationMin || '',
            duration__lte: durationMax || '',
            // eslint-disable-next-line no-nested-ternary
            ordering: sortByDuration ? `duration${sortByDuration === 1 ? '' : '-'}` : sortByTimestamp ? `created_at${sortByTimestamp === 1 ? '' : '-'}` : '',
          });
        }
      });
  };

  useEffect(() => {
    fetchFlows(setFlows, true);
  }, []);

  const getFlowNameById = (flowId) => {
    const flow = flows.find((f) => f.id === flowId);
    return flow ? flow.name : '';
  };

  useEffect(() => {
    fetchConversations();
  }, [
    page,
    convStatus,
    emailStatus,
    sortByDuration,
    sortByTimestamp,
    startDateTime,
    endDateTime,
    filterFlow,
    durationMin,
    durationMax,
  ]);

  useEffect(() => {
    setSearchParams({
      page,
      status: convStatus || '',
      email_sent: emailStatus || '',
      created_at__gte: startDateTime ? dayjs(startDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
      created_at__lte: endDateTime ? dayjs(endDateTime).format('YYYY-MM-DDTHH:mm:ss') : '',
      flow: filterFlow || '',
      duration__gte: durationMin || '',
      duration__lte: durationMax || '',
      // eslint-disable-next-line no-nested-ternary
      ordering: sortByDuration ? `duration${sortByDuration === 1 ? '' : '-'}` : sortByTimestamp ? `created_at${sortByTimestamp === 1 ? '' : '-'}` : '',
    });
  }, [
    page, convStatus, emailStatus, sortByDuration, sortByTimestamp,
    startDateTime, endDateTime, filterFlow, durationMin, durationMax, setSearchParams,
  ]);

  const deleteConversation = (conversationId) => {
    Swal.fire({
      icon: 'warning',
      title: 'Confirmation',
      text: 'Are you sure you want to delete this conversation? You will not be able to recover it later!',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${CONVERSATION_LIST}${conversationId}/`)
          .then(() => {
            // eslint-disable-next-line max-len
            setConversations(conversations.filter((conversation) => conversation.id !== conversationId));
          })
          .catch((error) => {
            console.error('Error deleting conversation', error);
          });
      }
    });
  };

  const handleOpenFilters = () => {
    setFiltersDrawer({
      open: true,
      initialStatus: convStatus,
      initialEmailStatus: emailStatus,
      initialCreatedAfter: startDateTime,
      initialCreatedBefore: endDateTime,
      initialFlow: filterFlow,
      initialDurationMin: durationMin,
      initialDurationMax: durationMax,
    });
  };

  const handleCloseFilters = () => {
    setFiltersDrawer({
      open: false,
      initialStatus: convStatus,
      initialEmailStatus: emailStatus,
      initialCreatedAfter: startDateTime,
      initialCreatedBefore: endDateTime,
      initialFlow: filterFlow,
      initialDurationMin: durationMin,
      initialDurationMax: durationMax,
    });
  };

  return (
    <div>
      <FancyHeading text="Conversations" />
      <Button
        className="btn btn-yellow"
        onClick={handleOpenFilters}
        startIcon={<TbAdjustments />}
      >
        Open Filters
      </Button>
      <Drawer
        open={filtersDrawer.open}
        onClose={handleCloseFilters}
        PaperProps={{
          sx: { width: '350px', p: 3 },
          className: 'filters-drawer',
        }}
      >
        <FancyHeading text="Filters" />
        <label>
          Status
        </label>
        <select
          onChange={(e) => setConvStatus(e.target.value)}
          value={convStatus}
        >
          <option value="">All</option>
          <option value="done">Done</option>
          <option value="active">Active</option>
          <option value="hung_up">Hung Up</option>
        </select>
        <label>
          Email Status
        </label>
        <select
          onChange={(e) => setEmailStatus(e.target.value)}
          value={emailStatus}
        >
          <option value="">All</option>
          <option value="true">Sent</option>
          <option value="false">Not Sent</option>
        </select>
        <label>
          Timestamp after
        </label>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={startDateTime}
                onChange={(date) => {
                  if (endDateTime && dayjs(date).isAfter(endDateTime)) {
                    setEndDateTime(date);
                  }
                  setStartDateTime(date);
                }}
                format="DD/MM/YYYY HH:mm:ss"
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            className="btn btn-red btn-small"
            onClick={() => setStartDateTime(null)}
          >
            <TbTrash />
          </Button>
        </Box>
        <label>
          Timestamp before
        </label>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={endDateTime}
                onChange={(date) => {
                  if (startDateTime && dayjs(date).isBefore(startDateTime)) {
                    setStartDateTime(date);
                  }
                  setEndDateTime(date);
                }}
                format="DD/MM/YYYY HH:mm:ss"
              />
            </DemoContainer>
          </LocalizationProvider>
          <Button
            className="btn btn-red btn-small"
            onClick={() => setEndDateTime(null)}
          >
            <TbTrash />
          </Button>
        </Box>
        <label>
          Flow
        </label>
        <select
          onChange={(e) => setFilterFlow(e.target.value)}
          value={filterFlow}
        >
          <option value="">All</option>
          {flows.map((flow) => (
            <option key={flow.id} value={flow.id}>{flow.name}</option>
          ))}
        </select>
        <label>Duration</label>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <input
            type="number"
            placeholder="Min"
            onChange={(e) => setDurationMin(e.target.value)}
            value={durationMin}
            step={0.01}
          />
          <Box sx={{ px: 1 }}>
            -
          </Box>
          <input
            type="number"
            placeholder="Max"
            onChange={(e) => setDurationMax(e.target.value)}
            value={durationMax}
            step={0.01}
          />
        </Box>
      </Drawer>
      <Table className="styled-table">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Caller</TableCell>
            <TableCell>
              <NToggleChip
                label="Timestamp"
                setOuterState={setSortByTimestamp}
                states={[0, 1, 2]}
                append={['-', '↑', '↓']}
              />
            </TableCell>
            <TableCell>Flow</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Email Status</TableCell>
            <TableCell>
              <NToggleChip
                label="Duration"
                setOuterState={setSortByDuration}
                states={[0, 1, 2]}
                append={['-', '↑', '↓']}
              />
            </TableCell>
            <TableCell align="right">Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {conversations.map((conversation) => (
            <TableRow key={conversation.id}>
              <TableCell>
                #
                <b>{conversation.numerical_id}</b>
              </TableCell>
              <TableCell>{`+${conversation.caller}`}</TableCell>
              <TableCell>
                <Chip label={(
                  <>
                    <TbCalendarTime style={{ verticalAlign: 'text-top', marginRight: 5 }} />
                    {new Date(conversation.created_at).toLocaleString()}
                  </>
                )}
                />
              </TableCell>
              <TableCell>{getFlowNameById(conversation.flow)}</TableCell>
              <TableCell>
                <StatusChip status={conversation.status} />
              </TableCell>
              <TableCell>
                <EmailStatusChip emailSent={conversation.email_sent} />
              </TableCell>
              <TableCell>{conversation.duration}</TableCell>
              <TableCell align="right">
                <Button
                  className="btn btn-red btn-small"
                  startIcon={<TbTrash />}
                  sx={{ mr: 1 }}
                  onClick={() => deleteConversation(conversation.id)}
                >
                  Delete
                </Button>
                <Button
                  className="btn btn-yellow btn-small"
                  onClick={() => navigate(`/conversations/${conversation.id}`)}
                  endIcon={<TbArrowRight />}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Pagination
        count={pages}
        page={page}
        onChange={(event, value) => {
          setPage(value);
          setSearchParams({ page: value });
        }}
        sx={{ mt: 3 }}
      />
    </div>
  );
}

export default ConversationsTableView;
