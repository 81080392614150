// providerConfig.js
const providerConfig = {
  google: [
    { label: 'Server URL', name: 'server_url', type: 'text' },
    { label: 'Redirect URI', name: 'redirect_uri', type: 'text' },
  ],
  microsoft: [
    { label: 'Tenant ID', name: 'tenant_id', type: 'text' },
  ],
};

export default providerConfig;
