// ProviderModal.jsx
import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button,
  TextField, Select, MenuItem, FormControl, InputLabel, Grid,
} from '@mui/material';
import providerConfig from './ProviderConfig';
import UserManagementContext from './UserManagementContext';

function ProviderModal({
  open, provider, onClose, isEdit,
}) {
  const [providerData, setProviderData] = useState({
    name: '',
    provider: 'google',
    provider_id: '',
    client_id: '',
    key: '',
    secret: '',
    settings: {},
  });
  const { addProvider, updateProvider } = useContext(UserManagementContext);

  useEffect(() => {
    if (isEdit && provider) {
      setProviderData(provider);
    } else {
      setProviderData({
        name: '',
        provider: 'google',
        provider_id: '',
        client_id: '',
        key: '',
        settings: {},
      });
    }
  }, [provider, isEdit]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProviderData({ ...providerData, [name]: value });
  };

  const handleSettingsChange = (e) => {
    const { name, value } = e.target;
    setProviderData({
      ...providerData,
      settings: { ...providerData.settings, [name]: value },
    });
  };

  const handleSave = () => {
    if (isEdit) {
      updateProvider(providerData);
    } else {
      addProvider(providerData);
    }
    onClose();
  };

  const fields = providerConfig[providerData.provider] || [];

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{isEdit ? 'Edit Provider' : 'Add New Provider'}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Provider Name"
              type="text"
              name="name"
              value={providerData.name}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Provider</InputLabel>
              <Select
                name="provider"
                value={providerData.provider}
                onChange={handleInputChange}
              >
                <MenuItem value="google">Google</MenuItem>
                <MenuItem value="microsoft">Microsoft</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Provider ID"
              type="text"
              name="provider_id"
              value={providerData.provider_id}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Client ID"
              type="text"
              name="client_id"
              value={providerData.client_id}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="secret"
              type="text"
              name="key"
              value={providerData.key}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              margin="dense"
              label="Key"
              type="text"
              name="key"
              value={providerData.key}
              onChange={handleInputChange}
              fullWidth
            />
          </Grid>
          {fields.map((field) => (
            <Grid item xs={12} key={field.name}>
              <TextField
                margin="dense"
                label={field.label}
                type={field.type}
                name={field.name}
                value={providerData.settings[field.name] || ''}
                onChange={handleSettingsChange}
                fullWidth
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained" color="primary">
          {isEdit ? 'Save' : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ProviderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  provider: PropTypes.shape({
    id: PropTypes.number,
    provider: PropTypes.string,
    provider_id: PropTypes.string,
    name: PropTypes.string,
    client_id: PropTypes.string,
    key: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    settings: PropTypes.object,
  }),
  onClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool.isRequired,
};

ProviderModal.defaultProps = {
  provider: null,
};

export default ProviderModal;
