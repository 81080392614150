// ActiveProviderCard.jsx
import React from 'react';
import PropTypes from 'prop-types';
import {
  Card, CardContent, Typography, Button,
} from '@mui/material';

function ActiveProviderCard({ provider, onEdit, onDelete }) {
  return (
    <Card sx={{ minWidth: 275, margin: 2 }}>
      <CardContent>
        <Typography variant="h5" component="div">
          {provider.name}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          Provider:
          {' '}
          {provider.provider}
        </Typography>
        <Typography variant="body2">
          Provider ID:
          {' '}
          {provider.provider_id}
          <br />
          Client ID:
          {' '}
          {provider.client_id}
          <br />
          Key:
          {' '}
          {provider.key}
        </Typography>
        {provider.settings && (
        <div>
          <Typography variant="body2">
            Settings:
            <ul>
              {Object.entries(provider.settings).map(([key, value]) => (
                <li key={key}>
                  <strong>
                    {key}
                    :
                  </strong>
                  {' '}
                  {value}
                </li>
              ))}
            </ul>
          </Typography>
        </div>
        )}
        <Button variant="outlined" size="small" onClick={() => onEdit(provider)}>
          Edit
        </Button>
        <Button variant="outlined" size="small" onClick={() => onDelete(provider)}>
          Delete
        </Button>
      </CardContent>
    </Card>
  );
}

ActiveProviderCard.propTypes = {
  provider: PropTypes.shape({
    id: PropTypes.number.isRequired,
    provider: PropTypes.string.isRequired,
    provider_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    client_id: PropTypes.string.isRequired,
    key: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    settings: PropTypes.object,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ActiveProviderCard;
