import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Dialog, DialogTitle, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
} from '@mui/material';
import { TbArrowRight, TbEdit } from 'react-icons/tb';
import {
  BiMessageSquareDots, BiMessageSquareError, BiMessageSquareMinus, BiMessageSquareX,
} from 'react-icons/bi';
import { MdAlternateEmail, MdNumbers, MdOutlineMail } from 'react-icons/md';
import { FiPhone } from 'react-icons/fi';
import EditableFact from '../EditableFact';

function FlowDetailSideBar({
  voices,
  activeFlow,
  getVoiceNameById,
  handleVoiceChange,
  handleFlowDetailChange,
  handleActivateClick,
}) {
  const [editVoice, setEditVoice] = useState(false);

  const openEditVoiceDialog = () => {
    setEditVoice(true);
  };

  const closeEditVoiceDialog = () => {
    setEditVoice(false);
  };

  return (
    <div className="flow-details">
      <div className="box">
        <h4>Flow Details</h4>
        <div>
          <h5 style={{ marginBottom: 10 }}>Voice</h5>
          <div className="voice-selector">
            <input
              type="text"
              value={getVoiceNameById(activeFlow.elevenlabs_voice) || 'No Voice Selected'}
              disabled
            />
            <Button
              className="btn btn-yellow btn-icon"
              onClick={openEditVoiceDialog}
            >
              <TbEdit />
            </Button>
            <Dialog
              maxWidth="xs"
              fullWidth
              open={editVoice}
              onClose={closeEditVoiceDialog}
            >
              <DialogTitle sx={{ pb: 0 }}>Select Voice</DialogTitle>
              <List disablePadding>
                {voices && voices.map((voice) => (
                  <ListItem disablePadding key={voice.id}>
                    <ListItemButton
                      sx={{ paddingX: 3 }}
                      onClick={() => {
                        handleVoiceChange(voice.id);
                        closeEditVoiceDialog();
                      }}
                    >
                      {activeFlow.elevenlabs_voice === voice.id && (
                        <ListItemIcon sx={{ minWidth: 'unset', mr: 2 }}>
                          <TbArrowRight />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={voice.name}
                        secondary={`Description: ${voice.description} | Gender: ${voice.gender}`}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Dialog>
          </div>
        </div>

        <EditableFact
          icon={<BiMessageSquareError />}
          title="Retry Message"
          databaseName="retry_message"
          noMargin={false}
          value={activeFlow.retry_message}
          playable
          voiceId={activeFlow.elevenlabs_voice}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<BiMessageSquareX />}
          title="Cut Off Message"
          databaseName="cutoff_message"
          noMargin={false}
          value={activeFlow.cutoff_message}
          playable
          voiceId={activeFlow.elevenlabs_voice}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<BiMessageSquareDots />}
          title="Breakout Message"
          databaseName="breakout_message"
          noMargin={false}
          value={activeFlow.breakout_message}
          playable
          voiceId={activeFlow.elevenlabs_voice}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<BiMessageSquareMinus />}
          title="Forward Message"
          databaseName="forward_to_human_message"
          noMargin={false}
          value={activeFlow.forward_to_human_message}
          playable
          voiceId={activeFlow.elevenlabs_voice}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<MdAlternateEmail />}
          title="Email Recipient"
          databaseName="email_recipient"
          noMargin={false}
          value={activeFlow.email_recipient}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<MdOutlineMail />}
          title="Email Subject"
          databaseName="email_subject"
          noMargin={false}
          value={activeFlow.email_subject}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<FiPhone />}
          title="Phone Number"
          databaseName="phone_number"
          noMargin={false}
          editable={false}
          value={activeFlow.phone_number}
          onFieldChange={handleFlowDetailChange}
        />
        <EditableFact
          icon={<MdNumbers />}
          title="Retries Before Forwarding"
          databaseName="retries_before_breakout"
          noMargin
          editable={false}
          value={activeFlow.retries_before_breakout}
          onFieldChange={handleFlowDetailChange}
        />
        <Button
          className="btn btn-red"
          onClick={handleActivateClick}
        >
          {activeFlow.active ? 'Deactivate Flow' : 'Activate Flow'}
        </Button>
      </div>
    </div>
  );
}

FlowDetailSideBar.propTypes = {
  voices: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
    gender: PropTypes.string,
  })).isRequired,
  activeFlow: PropTypes.shape({
    id: PropTypes.string.isRequired,
    elevenlabs_voice: PropTypes.string,
    retry_message: PropTypes.string,
    cutoff_message: PropTypes.string,
    breakout_message: PropTypes.string,
    forward_to_human_message: PropTypes.string,
    email_recipient: PropTypes.string,
    email_subject: PropTypes.string,
    phone_number: PropTypes.string,
    retries_before_breakout: PropTypes.number,
    active: PropTypes.bool,
  }).isRequired,
  getVoiceNameById: PropTypes.func.isRequired,
  handleVoiceChange: PropTypes.func.isRequired,
  handleFlowDetailChange: PropTypes.func.isRequired,
  handleActivateClick: PropTypes.func.isRequired,
};

export default FlowDetailSideBar;
