import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import {
  Button, Container,
} from '@mui/material';
import PropTypes from 'prop-types';
import './NavigationBar.scss';
import { TbPower, TbUser, TbUserSquareRounded } from 'react-icons/tb';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function NavigationBar({ pages }) {
  const location = useLocation();
  const { user, logout } = useContext(UserContext);

  const userHasRequiredRole = (roles) => {
    if (roles.length === 0) {
      return true;
    }

    if (!user) {
      return false;
    }

    // if user.permissions contains a string of the role, return true
    return roles.some((role) => user?.permissions?.includes(role));
  };

  return (
    <div className="header">
      <Container maxWidth="lg">
        <div className="header-inner">
          <div className="header-logo">
            <img src="/logo.png" alt="Logo" />
          </div>
          <div className="header-nav">
            <nav>
              {Object.keys(pages).map((key) => {
                const page = pages[key];
                if (page.showMenu && userHasRequiredRole(page.roleNeeded)) {
                  const isActive = location.pathname === page.path;
                  const linkText = page.label || key.charAt(0).toUpperCase() + key.slice(1).replace(/\s+$/, '');
                  return (
                    <a
                      key={page.id}
                      href={page.path}
                      className={`nav-link ${isActive ? 'active' : ''}`}
                    >
                      {linkText}
                    </a>
                  );
                }
                return null;
              })}
            </nav>
            {user && user.email ? (
              <div className="header-user">
                <TbUserSquareRounded className="user-icon" />
                <div className="header-user-info">
                  <span>{user && user.name}</span>
                  {user && user.email}
                </div>
                <Button
                  className="btn btn-yellow btn-icon"
                  sx={{ ml: 2 }}
                  onClick={() => logout()}
                >
                  <TbPower />
                </Button>
              </div>
            ) : (
              <Button
                className="btn btn-yellow btn-small"
                startIcon={<TbUser />}
                href="/login"
              >
                Log In
              </Button>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}

NavigationBar.propTypes = {
  pages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      showMenu: PropTypes.bool,
      label: PropTypes.string,
      roleNeeded: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default NavigationBar;
