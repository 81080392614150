import React from 'react';
import PropTypes from 'prop-types';

function FancyHeading({ text }) {
  return (
    <div className="fancy-heading">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="74" viewBox="0 0 14 74" fill="none">
        <path d="M1.42318e-05 66.755C-0.00240397 67.994 0.303384 69.2128 0.888049 70.2945C1.47271 71.3763 2.31663 72.2847 3.33886 72.9326C4.36108 73.5805 5.5273 73.9462 6.72566 73.9945C7.92401 74.0428 9.11429 73.7722 10.1823 73.2087C11.2503 72.6451 12.1602 71.8074 12.8247 70.7761C13.4893 69.7447 13.8861 68.5542 13.9772 67.3188C14.0683 66.0834 13.8506 64.8446 13.345 63.7211C12.8394 62.5976 12.0628 61.6272 11.0897 60.903V13.0989C12.3055 12.1949 13.2084 10.9117 13.6658 9.43809C14.1231 7.96447 14.1108 6.37813 13.6306 4.9123C13.1503 3.44647 12.2275 2.17851 10.9978 1.29481C9.76814 0.411117 8.29645 -0.0416756 6.79914 0.00301741C5.30184 0.0477105 3.85792 0.587518 2.67968 1.54309C1.50143 2.49866 0.651028 3.81956 0.253502 5.31159C-0.144023 6.80362 -0.0676937 8.38806 0.471261 9.83194C1.01022 11.2758 1.98336 12.503 3.24762 13.333V60.6689C2.25344 61.3182 1.43435 62.2166 0.866533 63.2807C0.29872 64.3447 0.000640147 65.5399 1.42318e-05 66.755Z" fill="#F45050" />
      </svg>
      <h1>{text}</h1>
    </div>
  );
}

FancyHeading.propTypes = {
  text: PropTypes.string.isRequired,
};

export default FancyHeading;
