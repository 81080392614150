import React from 'react';
import LoginView from '../Views/Login';
import RecordingView from '../Views/RecordingView';
import ConversationsTableView from '../Views/ConversationsTableView';
import TranscriptView from '../Views/TranscriptView';
import FlowBuilderView from '../Views/FlowBuilderView';
import FlowOverview from '../Views/FlowOverview';
import AccessManagementView from '../Views/UserManagement';

export default function Pages() {
  return {
    login: {
      id: 1, showMenu: false, path: '/login', element: <LoginView />, roleNeeded: [],
    },
    conversations: {
      id: 2, path: '/', loginNeeded: true, showMenu: true, element: <ConversationsTableView />, roleNeeded: [],
    },
    recording: {
      id: 3, path: '/recording', loginNeeded: true, showMenu: true, element: <RecordingView />, roleNeeded: ['start_conversation'],
    },
    transcripts: {
      id: 4, path: '/conversations/:conversationId', loginNeeded: true, showMenu: false, element: <TranscriptView />, roleNeeded: ['view_conversation_detail'],
    },
    flow_builder: {
      id: 5, path: '/flowbuilder/:flowId', label: 'Flow Builder', loginNeeded: true, showMenu: false, element: <FlowBuilderView />, roleNeeded: ['view_flowbuilder'],
    },
    flow_overview: {
      id: 6, path: '/flowoverview', label: 'Flows', loginNeeded: true, showMenu: true, element: <FlowOverview />, roleNeeded: ['view_flowbuilder'],
    },
    user_management: {
      id: 7, path: '/access-management', label: 'Access Management', loginNeeded: true, showMenu: true, element: <AccessManagementView />, roleNeeded: [],
    },
  };
}
