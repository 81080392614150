import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

function NToggleChip({
  label, setOuterState, states, colors, append,
}) {
  const [internalState, setInetrnalState] = useState(null);

  const handleClick = () => {
    let newInternalState;
    if (internalState === null) {
      [, newInternalState] = states;
    } else {
      const currentIndex = states.indexOf(internalState);
      newInternalState = states[(currentIndex + 1) % states.length];
    }

    setInetrnalState(newInternalState);
    console.log('newInternalState', newInternalState);
    setOuterState(newInternalState);
  };

  const getColor = () => {
    if (colors.length > 0) {
      return colors[states.indexOf(internalState)];
    }
    return 'default';
  };

  const getAppend = () => {
    if (append.length > 0) {
      if (internalState === null) {
        return ` ${append[0]}`;
      }
      return ` ${append[states.indexOf(internalState)]}`;
    }
    return '';
  };

  return (
    <Chip
      label={`${label}${getAppend()}`}
      onClick={handleClick}
      color={getColor()}
    />
  );
}

export default NToggleChip;

NToggleChip.propTypes = {
  label: PropTypes.string.isRequired,
  setOuterState: PropTypes.func.isRequired,
  states: PropTypes.arrayOf(PropTypes.number).isRequired,
  colors: PropTypes.arrayOf(PropTypes.string),
  append: PropTypes.arrayOf(PropTypes.string),
};

NToggleChip.defaultProps = {
  colors: [],
  append: [],
};
