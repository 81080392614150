export default function getApiHost() {
  let url = process.env.REACT_APP_API_URL;

  while (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return url;
}

export function getWebsocketHost() {
  let url = process.env.REACT_APP_WS_URL;

  while (url.endsWith('/')) {
    url = url.substring(0, url.length - 1);
  }

  return url;
}
