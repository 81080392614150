import axios from 'axios';
import { useContext } from 'react';
import { UserContext } from '../Providers/UserProvider/UserProvider';
import { mapToAuthRequest } from './RequestUtils';

// Function to create the API utility with configuration directly
export function createApiUtilsWithConfig(getConfig, refresh, logout) {
  function mapToAuthRequestLoc(request) {
    return mapToAuthRequest(request, getConfig, refresh, logout);
  }

  return {
    get(url, configParams = {}) {
      const request = (conf) => axios.get(url, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    put(url, data = {}, configParams = {}) {
      const request = (conf) => axios.put(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    patch(url, data = {}, configParams = {}) {
      const request = (conf) => axios.patch(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    post(url, data = {}, configParams = {}) {
      const request = (conf) => axios.post(url, data, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    del(url, configParams = {}) {
      const request = (conf) => axios.delete(url, { ...conf, ...configParams });
      return mapToAuthRequestLoc(request);
    },
    postNoAuth(url, data = {}, configParams = {}) {
      return axios.post(url, data, configParams)
        .catch((error) => {
          throw error;
        });
    },
    getNoAuth(url = {}, configParams = {}) {
      return axios.get(url, configParams)
        .catch((error) => {
          throw error;
        });
    },
  };
}

// Default export that uses useContext hook
export default function apiUtils() {
  const { getConfig, refresh, logout } = useContext(UserContext);
  return createApiUtilsWithConfig(getConfig, refresh, logout);
}
