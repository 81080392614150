import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Chip,
} from '@mui/material';
import { TbTrash } from 'react-icons/tb';
import { MdOutlineCreate } from 'react-icons/md';
import Swal from 'sweetalert2';
import apiUtils from '../../Utils/ApiUtils';
import { PHONE_NUMBERS_URL } from '../../Constants/URLS';
import './PhoneNumbers.scss';
import FancyHeading from '../FancyHeading';

function PhoneNumbersTable() {
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [loading, setLoading] = useState(true);
  const api = apiUtils();

  const fetchPhoneNumbers = () => {
    setLoading(true);
    api.get(PHONE_NUMBERS_URL)
      .then((response) => {
        setPhoneNumbers(response.data.results);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching phone numbers:', error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchPhoneNumbers();
  }, []);

  const addPhoneNumber = () => {
    const newPhoneNumber = {
      provider: 'Vonage',
      country_code: 'NL',
    };
    api.post(PHONE_NUMBERS_URL, newPhoneNumber)
      .then(() => {
        fetchPhoneNumbers(); // Refresh the list after adding
      })
      .catch((error) => {
        console.error('Error adding a new phone number:', error);
      });
  };

  const deletePhoneNumber = (id) => {
    // eslint-disable-next-line no-shadow
    const phoneNumber = phoneNumbers.find((phoneNumber) => phoneNumber.id === id).phone_number;
    const alertText = `Are you sure you want to delete this phone number? ${phoneNumber}`;
    Swal.fire({
      title: 'Delete Phone Number',
      text: alertText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${PHONE_NUMBERS_URL}${id}`)
          .then(() => {
            fetchPhoneNumbers();
          })
          .catch((error) => {
            console.error('Error deleting the phone number:', error);
          });
      }
    });
  };

  // eslint-disable-next-line react/prop-types
  function StatusChip({ provisioned }) {
    return <Chip label={provisioned ? 'Yes' : 'No'} color={provisioned ? 'success' : 'error'} />;
  }

  return (
    <div>
      <FancyHeading text="Phone Numbers" />
      <Button
        className="btn btn-yellow"
        startIcon={<MdOutlineCreate />}
        color="primary"
        onClick={addPhoneNumber}
      >
        Add Phone Number
      </Button>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <TableContainer>
          <Table className="styled-table">
            <TableHead>
              <TableRow>
                <TableCell>Phone Number</TableCell>
                <TableCell>Country Code</TableCell>
                <TableCell>Provider</TableCell>
                <TableCell>Provisioned</TableCell>
                <TableCell>Flow</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {phoneNumbers.map((phoneNumber) => (
                <TableRow key={phoneNumber.id}>
                  <TableCell><strong>{phoneNumber.phone_number}</strong></TableCell>
                  <TableCell>{phoneNumber.country_code}</TableCell>
                  <TableCell>{phoneNumber.provider}</TableCell>
                  <TableCell><StatusChip provisioned={phoneNumber.provisioned} /></TableCell>
                  <TableCell>{phoneNumber.flow}</TableCell>
                  <TableCell align="right">
                    <Button
                      className="btn btn-red"
                      color="secondary"
                      startIcon={<TbTrash />}
                      onClick={() => deletePhoneNumber(phoneNumber.id)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default PhoneNumbersTable;
