import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from '@mui/material';
import './EditableFact.scss';
import {
  TbDeviceFloppy, TbPencil, TbPlayerPause, TbPlayerPlay,
} from 'react-icons/tb';
import { Howl } from 'howler';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { fetchAudioSampleAction } from '../../Views/FlowBuilderView/flowNodeActions';

function EditableFact({
  icon, title, databaseName, value, noMargin, editable, onFieldChange, playable, voiceId,
}) {
  const [currentValue, setCurrentValue] = useState(value);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState(value);
  const [isPlaying, setIsPlaying] = useState(false);
  const playerRef = useRef(null);
  const { getConfig, refresh, logout } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setCurrentValue(value);
    setModalValue(value);
  }, [value]);

  // eslint-disable-next-line max-len
  const fetchAudioSample = async (text) => fetchAudioSampleAction(text, voiceId, title, getConfig, refresh, logout);

  const handleModalOpen = () => {
    if (editable) {
      setModalOpen(true);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setModalValue(currentValue);
  };

  const handleInputChange = (event) => {
    setModalValue(event.target.value);
  };

  const handleSave = () => {
    setCurrentValue(modalValue);
    onFieldChange(databaseName, modalValue);
    setModalOpen(false);
  };

  const playAudio = (url) => {
    if (playerRef.current) {
      playerRef.current.stop();
    }

    const newPlayer = new Howl({
      src: [url],
      autoplay: false,
      format: ['mp3'],
      onplay: () => setIsPlaying(true),
      onend: () => setIsPlaying(false),
      onpause: () => setIsPlaying(false),
    });

    playerRef.current = newPlayer;
    newPlayer.play();
  };

  const pauseAudio = () => {
    if (playerRef.current) {
      playerRef.current.pause();
    }
  };

  return (
    <div
      className="fact editable-fact"
      style={noMargin ? { marginBottom: 0 } : {}}
    >
      <div className="fact-icon">
        {icon}
      </div>
      <div className="fact-text">
        <h4>
          {currentValue || 'None'}
          {editable && (
            <Button
              className="edit-button"
              onClick={handleModalOpen}
            >
              <TbPencil />
            </Button>
          )}
          {playable && (
            <Button
              className="play-button"
              onClick={(e) => {
                e.stopPropagation();
                if (isPlaying) {
                  pauseAudio();
                } else {
                  setLoading(true);
                  fetchAudioSample(currentValue)
                    .then((audio) => {
                      playAudio(audio);
                      setLoading(false);
                    })
                    .catch((error) => {
                      console.error('Error fetching audio sample:', error);
                      setLoading(false);
                    });
                }
              }}
            >
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading ? (
                <div className="spinner" />
              ) : (
                isPlaying ? <TbPlayerPause /> : <TbPlayerPlay />
              )}
            </Button>
          )}
        </h4>
        <p>{title}</p>
      </div>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
      >
        <div className="modal-content edit-fact-modal">
          <h4 id="modal-title">
            Edit Flow Detail
          </h4>
          <label className="form-label">{title}</label>
          <input
            type="text"
            value={modalValue}
            onChange={handleInputChange}
            placeholder={`${title}...`}
          />
          <Button
            className="btn btn-yellow"
            startIcon={<TbDeviceFloppy />}
            onClick={handleSave}
            sx={{ mt: 2 }}
          >
            Save Detail
          </Button>
        </div>
      </Modal>
    </div>
  );
}

EditableFact.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  databaseName: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  editable: PropTypes.bool,
  noMargin: PropTypes.bool,
  onFieldChange: PropTypes.func.isRequired,
  playable: PropTypes.bool,
  voiceId: PropTypes.string,
};

EditableFact.defaultProps = {
  noMargin: false,
  editable: true,
  playable: false,
  voiceId: null,
};

export default EditableFact;
