import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { TbArrowsJoin, TbPlayerStop } from 'react-icons/tb';
import AudioHandler from './AudioHandler';
import { AudioDataProvider } from './AudioDataContext';
import { CONVERSATION_IO_WS_URL } from '../../Constants/URLS';
import FancyHeading from '../../Components/FancyHeading';
import { fetchFlows } from '../FlowBuilderView/flowNodeAPI';

function RecordingView() {
  const [isRecording, setIsRecording] = useState(false);
  const [isConversation, setIsConversation] = useState(false);
  const [language, setLanguage] = useState('nl'); // Default language set to Dutch
  const [flows, setFlows] = useState([]); // State to store the flows from the DB
  const [selectedFlow, setSelectedFlow] = useState(''); // State to store the selected flow

  const languages = [
    { code: 'en', label: 'English 🇬🇧' },
    { code: 'nl', label: 'Nederlands 🇳🇱' },
    { code: 'fr', label: 'Français 🇫🇷' },
  ];

  useEffect(() => {
    fetchFlows(setFlows, false);
  }, []);

  const startConversation = () => {
    console.log(`[${new Date().toISOString()}] start conversation`);
    setIsConversation(true);
    setIsRecording(false);
  };

  const stopConversation = () => {
    setIsConversation(false);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleFlowChange = (event) => {
    setSelectedFlow(event.target.value);
  };

  return (
    <AudioDataProvider>
      <div>
        <select id="language-selector" value={language} onChange={handleLanguageChange} disabled={isRecording || isConversation}>
          {languages.map((lang) => (
            <option key={lang.code} value={lang.code}>{lang.label}</option>
          ))}
        </select>
        <br />
        <br />

        <FancyHeading text="Conversational Flow" />

        <b>
          Flow:
          {' '}
        </b>
        <select
          id="flow-selector"
          value={selectedFlow}
          onChange={handleFlowChange}
          disabled={isRecording || isConversation || !flows || flows.length === 0}
        >
          {flows && flows.length > 0 ? (
            flows.map((flow) => (
              <option key={flow.id} value={flow.id}>{flow.name}</option>
            ))
          ) : (
            <option value="">No flows available</option>
          )}
        </select>
        <br />

        <br />

        <Button
          type="button"
          onClick={startConversation}
          disabled={!selectedFlow || isRecording || isConversation}
          className="btn btn-yellow"
          startIcon={<TbArrowsJoin />}
          sx={{ mr: 2 }}
        >
          Start Conversation Flow
        </Button>

        <Button
          type="button"
          onClick={stopConversation}
          disabled={!isConversation}
          className="btn btn-black"
          startIcon={<TbPlayerStop />}
        >
          Stop Conversation Flow
        </Button>

        <AudioHandler
          isRecording={isRecording || isConversation}
          wsUrl={CONVERSATION_IO_WS_URL}
          language={language}
          selectedFlow={selectedFlow}
          onConversationEnd={stopConversation}
        />
      </div>
    </AudioDataProvider>
  );
}

export default RecordingView;
