// UserManagementContext.jsx
import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiUtils from '../../Utils/ApiUtils';
import { PERMISSION_GROUPS_URL, PROVIDERS_URL, USERS_URL } from '../../Constants/URLS';

const UserManagementContext = createContext();

function UserManagementProvider({ children }) {
  const [activeProviders, setActiveProviders] = useState([]);
  const [permissionGroups, setPermissionGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const api = apiUtils();

  const fetchActiveProviders = async () => {
    try {
      const response = await api.get(PROVIDERS_URL);
      setActiveProviders(response.data.results);
    } catch (error) {
      console.error('Error fetching active providers:', error);
    }
  };

  const fetchPermissionGroups = async () => {
    try {
      const response = await api.get(PERMISSION_GROUPS_URL);
      setPermissionGroups(response.data.results);
    } catch (error) {
      console.error('Error fetching permission groups:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await api.get(USERS_URL);
      setUsers(response.data.results);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchActiveProviders();
    fetchUsers();
    fetchPermissionGroups();
  }, []);

  const addProvider = async (newProvider) => {
    try {
      const response = await api.post(PROVIDERS_URL, newProvider);
      setActiveProviders([...activeProviders, response.data]);
    } catch (error) {
      console.error('Error adding provider:', error);
    }
  };

  const updateProvider = async (updatedProvider) => {
    try {
      const response = await api.patch(`${PROVIDERS_URL}${updatedProvider.id}/`, updatedProvider);
      setActiveProviders(
        // eslint-disable-next-line max-len
        activeProviders.map((provider) => (provider.id === updatedProvider.id ? response.data : provider)),
      );
    } catch (error) {
      console.error('Error updating provider:', error);
    }
  };

  const deleteProvider = async (provider) => {
    try {
      await api.del(`${PROVIDERS_URL}${provider.id}/`);
      setActiveProviders(activeProviders.filter((p) => p.id !== provider.id));
    } catch (error) {
      console.error('Error deleting provider:', error);
    }
  };

  const updateUserPermissionGroups = async (userId, permissionGroupsId) => {
    try {
      await api.patch(`${USERS_URL}${userId}/`, { groups: permissionGroupsId });
      fetchUsers();
    } catch (error) {
      console.error('Error updating user permission group:', error);
    }
  };

  return (
    <UserManagementContext.Provider value={{
      // eslint-disable-next-line max-len
      activeProviders, users, addProvider, updateProvider, deleteProvider, permissionGroups, updateUserPermissionGroups,
    }}
    >
      {children}
    </UserManagementContext.Provider>
  );
}

UserManagementProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserManagementProvider };
export default UserManagementContext;
