import {
  saveNodeChanges,
  saveFlowChanges,
  addNode,
  deleteNode,
  createFlow,
  deleteFlow,
  fetchAudioSample,
  fetchFlowById,
} from './flowNodeAPI';

export const updateNode = async (nodeId, activeFlowId, nodeData) => {
  await saveNodeChanges(nodeId, activeFlowId, nodeData);
};

export const updateFlow = async (flowId, flowData) => {
  await saveFlowChanges(flowId, flowData);
};

// eslint-disable-next-line max-len
export const addNodeAction = async (lastNodeId, question, name, type, required, extraFields, flowId) => {
  try {
    const response = await addNode(lastNodeId, question, name, type, required, flowId, extraFields);

    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    console.error('Error adding node:', error);
    return null;
  }
};

export const deleteNodeAction = async (nodeId) => {
  try {
    const response = await deleteNode(nodeId);
    console.log('Deletion Response', response);

    return response;
  } catch (error) {
    console.error('Error deleting node:', error);
    return false;
  }
};

export const createFlowAction = async (newFlowName) => {
  try {
    const response = await createFlow(newFlowName);

    return response;
  } catch (error) {
    console.error('Error creating flow:', error);
    return false;
  }
};

export const deleteFlowAction = async (flowId) => {
  try {
    const response = await deleteFlow(flowId);

    return response;
  } catch (error) {
    console.error('Error deleting flow:', error);
    return false;
  }
};

// eslint-disable-next-line max-len
export const fetchAudioSampleAction = async (text, voiceId, sampleKey, getConfig, refresh, logout) => {
  try {
    const audioData = await fetchAudioSample(voiceId, text, getConfig, refresh, logout);
    const audioBlob = new Blob([audioData], { type: 'audio/mpeg' });
    const audioUrl = URL.createObjectURL(audioBlob);

    if (audioUrl) {
      return audioUrl;
    }
    return null;
  } catch (error) {
    console.error(`Failed to fetch voice samples for ${sampleKey}:`, error);
    return null;
  }
};

export const fetchFlow = async (flowId, getConfig, refresh, logout) => {
  try {
    const response = await fetchFlowById(flowId, getConfig, refresh, logout);
    return response;
  } catch (error) {
    console.error('Failed to fetch flow:', error);
    return null;
  }
};
