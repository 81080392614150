import {
  fillInIdsInUrl,
  ELEVENLABS_VOICES_URLS,
  CONVERSATIONAL_FLOW_LIST,
  NODE_TYPES_URL,
  NODES_URL,
  FLOWS_URL,
  ELEVENLABS_VOICE_SAMPLES_URL,
} from '../../Constants/URLS';

import { createApiUtilsWithConfig } from '../../Utils/ApiUtils';

export async function saveNodeChanges(nodeId, activeFlowId, nodeData) {
  const {
    name, question, type, required, ...rest
  } = nodeData;

  const nodeToUpdate = {
    id: nodeId,
    flow: activeFlowId,
    node_name: name,
    question,
    node_type: type,
    required,
    ...rest,
  };

  try {
    const response = await fetch(`${NODES_URL}/${nodeId}/`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
      body: JSON.stringify(nodeToUpdate),
    });
    if (!response.ok) {
      throw new Error('Failed to save node changes');
    }
  } catch (error) {
    console.error('Error saving node changes:', error);
  }
}

export async function saveFlowChanges(flowId, flowData) {
  if (Object.keys(flowData).length === 0) {
    console.log('No flow details to update.');
    return;
  }

  try {
    const response = await fetch(`${FLOWS_URL}/${flowId}/`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
      body: JSON.stringify(flowData),
    });
    if (!response.ok) {
      throw new Error('Failed to save flow changes');
    }
  } catch (error) {
    console.error('Error saving flow changes:', error);
  }
}

export const fetchElevenLabsVoices = async () => {
  try {
    const voicesUrl = `${ELEVENLABS_VOICES_URLS}`;

    const response = await fetch(voicesUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
    });
    const data = await response.json();

    if (response.ok) {
      console.log('Voices:', data);
      return data;
    }
    console.error('Failed to fetch voices:', data);
    throw new Error('Failed to fetch voices');
  } catch (error) {
    console.error('Failed to fetch flows:', error);
    return [];
  }
};

// eslint-disable-next-line max-len
export const addNode = async (lastNodeId, nodeQuestion, nodeName, nodeType, nodeRequired, flowId, extraFields) => {
  const nodeData = {
    previous_node: lastNodeId,
    question: nodeQuestion,
    name: nodeName,
    type: nodeType,
    required: nodeRequired,
    flow_id: flowId,
    ...extraFields,
  };

  try {
    const response = await fetch(`${NODES_URL}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
      body: JSON.stringify(nodeData),
    });
    if (response.ok) {
      return await response.json();
    }
    throw new Error('Failed to add node');
  } catch (error) {
    console.error('Failed to add node:', error);
    return null;
  }
};

// eslint-disable-next-line max-len
export const fetchFlows = async (setFlowsCallback, includeDeleted, selectDefaultFlowCallback = null, initializeFlowDetailsCallback = null) => {
  try {
    const flowUrl = new URL(CONVERSATIONAL_FLOW_LIST);
    flowUrl.searchParams.append('deleted', includeDeleted);
    // eslint-disable-next-line max-len
    const response = await fetch(flowUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
    });
    const data = await response.json();
    if (response.ok) {
      console.log('Retrieved Flows:', data);
      setFlowsCallback(data);
      if (data.length > 0 && selectDefaultFlowCallback && initializeFlowDetailsCallback) {
        selectDefaultFlowCallback(data[0].id);
        initializeFlowDetailsCallback(data[0]);
      }
    } else {
      throw new Error('Failed to fetch flows');
    }
  } catch (error) {
    console.error('Failed to fetch flows:', error);
  }
};

export const fetchNodeTypes = async () => {
  try {
    const nodeTypesUrl = `${NODE_TYPES_URL}`;
    const response = await fetch(nodeTypesUrl, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
    });

    if (!response.ok) {
      throw new Error(`Network response was not ok: ${response.statusText}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Failed to fetch node types:', error);
    return [];
  }
};

export const deleteNode = async (nodeId) => {
  try {
    const response = await fetch(`${NODES_URL}/${nodeId}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
    });
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const createFlow = async (newFlowName) => {
  try {
    const response = await fetch(`${FLOWS_URL}/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
      body: JSON.stringify({ name: newFlowName }),
    });
    if (response.ok) {
      return await response.json();
    }
    return null;
  } catch (error) {
    console.error('Error creating flow:', error);
    return false;
  }
};

export const deleteFlow = async (flowId) => {
  try {
    const response = await fetch(`${FLOWS_URL}/${flowId}/`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('frontend_access_token')}`,
      },
    });
    return response.ok;
  } catch (error) {
    return false;
  }
};

export const fetchAudioSample = async (voiceId, text, getConfig, refresh, logout) => {
  const baseUrl = fillInIdsInUrl(ELEVENLABS_VOICE_SAMPLES_URL, voiceId);
  const url = new URL(baseUrl);
  url.searchParams.append('text', text);

  const api = createApiUtilsWithConfig(getConfig, refresh, logout);

  return api.get(url, { responseType: 'arraybuffer' })
    .then((response) => {
      if (response.status !== 200) {
        throw new Error(`Network response was not ok: ${response.statusText}`);
      }
      return response.data;
    })
    .catch((error) => {
      console.error('Failed to fetch voice samples:', error);
      throw error;
    });
};

// flowNodeAPI.js
export const fetchFlowById = async (id, getConfig, refresh, logout) => {
  const api = createApiUtilsWithConfig(getConfig, refresh, logout);

  try {
    const response = await api.get(`${FLOWS_URL}/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching flow by ID:', error);
    return null;
  }
};
