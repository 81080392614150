import { createContext, useReducer } from 'react';

export const audioDataReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_AUDIO_DATA':
      return { ...state, audioData: [...state.audioData, action.payload] };
    default:
      return state;
  }
};

const initialState = { audioData: [] };

export const AudioDataContext = createContext({
  state: initialState,
  dispatch: () => { },
});

// eslint-disable-next-line react/prop-types
export function AudioDataProvider({ children }) {
  const [state, dispatch] = useReducer(audioDataReducer, initialState);

  return (
    // eslint-disable-next-line react/react-in-jsx-scope
    <AudioDataContext.Provider value={{ state, dispatch }}>
      {children}
    </AudioDataContext.Provider>
  );
}
