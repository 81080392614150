import React, {
  useContext, useEffect, useReducer, useState,
} from 'react';
import { Button, CircularProgress } from '@mui/material';
import { Navigate } from 'react-router-dom';
import notificationManager from 'react-notifications/lib/NotificationManager';
import LineIcon from 'react-lineicons';
import { LOGIN_URL } from '../../Constants/URLS';
import apiUtils from '../../Utils/ApiUtils';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import loginReducer from './LoginReducer';
import SocialLogin from './SocialLogin';

function LoginView() {
  const { storeTokens, tokens } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [form, formDispatch] = useReducer(loginReducer, {
    fullFieldError: '',
    username: '',
    usernameError: '',
    password: '',
    passwordError: '',
    canSubmit: false,
    submitting: false,
  });

  const api = apiUtils();

  function tryLogin() {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);
    api.postNoAuth(LOGIN_URL, { email: form.username, password: form.password })
      .then((response) => {
        if (response?.status === 200) {
          formDispatch({ type: 'SUBMIT', payload: false });
          storeTokens(response.data.access, response.data.refresh);
        } else {
          notificationManager.error('', 'Wrong username and/or password');
        }
        setLoading(false);
      }).catch((data) => {
        notificationManager.error('Wrong username and/or password or no internet', 'Could not login');
        formDispatch({ type: 'SUBMIT', payload: false });
        if (data.response.status === 400) {
          formDispatch({ type: 'HANDLE_ERROR', payload: data.response.data });
        }
        setLoading(false);
      });
  }

  const loginSocial = (URL, credential = '', code = '') => {
    formDispatch({ type: 'SUBMIT', payload: true });
    setLoading(true);

    api.postNoAuth(URL, {
      access_token: credential,
      id_token: credential,
      code,
    }).then((response) => {
      if (response?.status === 200) {
        formDispatch({ type: 'SUBMIT', payload: false });
        storeTokens(response.data.access, response.data.refresh);
      } else {
        notificationManager.error('', 'Wrong username and/or password');
      }
      setLoading(false);
    }).catch((data) => {
      notificationManager.error('Wrong username and/or password or no internet', 'Could not login');
      formDispatch({ type: 'SUBMIT', payload: false });
      if (data.response.status === 400) {
        formDispatch({ type: 'HANDLE_ERROR', payload: data.response.data });
      }
      setLoading(false);
    });
  };

  useEffect(
    () => {
      if (form.canSubmit) tryLogin();
    },
    [form.canSubmit],
  );

  return (
    <div>
      {tokens?.accessToken === undefined || tokens.accessToken.length === 0 ? (
        <div className="login-form box">
          {form.fullFieldError && <div className="formError">{form.fullFieldError}</div>}
          <label htmlFor="username-input">
            {form.usernameError && <span className="inputError">{form.usernameError}</span>}
          </label>
          <input
            id="username-input"
            value={form.username}
            placeholder="Email"
            autoComplete="email"
            onChange={(e) => formDispatch({ type: 'USERNAME', payload: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                formDispatch({ type: 'VALIDATE' });
              }
            }}
          />
          <label htmlFor="password-input">
            {form.passwordError && <span className="inputError">{form.passwordError}</span>}
          </label>
          <input
            id="password-input"
            type="password"
            placeholder="Password"
            autoComplete="current-password"
            value={form.password}
            onChange={(e) => formDispatch({ type: 'PASSWORD', payload: e.target.value })}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                formDispatch({ type: 'VALIDATE' });
              }
            }}
          />
          <SocialLogin loginSocial={loginSocial} />
          <div className="formActionsRow">
            <Button
              onClick={() => formDispatch({ type: 'VALIDATE' })}
              className="btn btn-red"
              endIcon={!loading && <LineIcon name="arrow-right" />}
              disabled={loading}
            >
              {!loading ? 'Login' : <CircularProgress color="inherit" sx={{ height: 'inherit' }} />}
            </Button>
          </div>
        </div>
      ) : (
        <Navigate
          to={{
            pathname: '/',
          }}
        />
      )}
    </div>
  );
}

LoginView.propTypes = {};

export default LoginView;
