import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function AuthenticatedRoute({ element, roleNeeded }) {
  const { tokens, user } = useContext(UserContext);

  const hasRequiredRole = (roles) => {
    if (!roles.length) {
      return true;
    }

    // if user.permissions contains a string of the role, return true
    return roles.some((role) => user?.permissions?.includes(role));
  };

  if (!(tokens?.accessToken?.length > 0)) {
    return <Navigate to="/login" />;
  }
  if (Object.keys(user).length === 0) {
    return <></>;
  }
  console.log('User:', user);
  if (!hasRequiredRole(roleNeeded)) {
    return <Navigate to="/" />; // or wherever unauthorized users should go
  }
  return element;
}

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  roleNeeded: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AuthenticatedRoute;
