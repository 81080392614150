import getApiHost, { getWebsocketHost } from '../Utils/UrlUtils';

// eslint-disable-next-line import/prefer-default-export
const API_URL = `${getApiHost()}/api/v1`;
const WS_URL = `${getWebsocketHost()}/ws`;

export const fillInIdsInUrl = (baseUrl, ...ids) => {
  let url = baseUrl;
  ids.forEach((id) => {
    url = url.replace(/\{\w+\}/, id);
  });
  return url;
};

export const LOGIN_URL = `${API_URL}/auth/token/`;
export const CONVERSATION_LIST = `${API_URL}/conversations/`;
export const DELETE_CONVERSATION = `${API_URL}/delete-conversation/`;
export const CONVERSATION_URL = `${API_URL}/conversations/{conversationId}/`;
export const REQUEST_URL = `${API_URL}/users/me`;
export const REFRESH_URL = `${API_URL}/auth/token/refresh/`;
export const CONVERSATION_IO_WS_URL = `${WS_URL}/conversation_io/`;
export const CONVERSATIONAL_FLOW_LIST = `${API_URL}/conversations/flows/`;
export const NODE_TYPES_URL = `${API_URL}/conversations/node-types/`;
export const NODES_URL = `${API_URL}/conversations/flow-builder/nodes`;
export const FLOWS_URL = `${API_URL}/conversations/flow-builder/flows`;
export const CONVERSATION_AUDIO_URL = `${API_URL}/conversations/{conversationId}/audio/`;
export const ELEVENLABS_VOICES_URLS = `${API_URL}/tts/elevenlabs-voices/`;
export const ELEVENLABS_VOICE_SAMPLES_URL = `${API_URL}/tts/elevenlabs-voice-samples/get-audio/{voiceId}/`;
export const REPORT_MESSAGE_URL = `${API_URL}/conversations/{conversationId}/report-message/`;
export const DOWNLOAD_MESSAGE_AUDIO_URL = `${API_URL}/conversations/{conversationId}/download-message-audio/`;
export const TRANSCRIBE_MESSAGE_URL = `${API_URL}/conversations/{conversationId}/transcribe-message/`;
export const MARK_CONVERSATION_AS_DONE_URL = `${API_URL}/conversations/{conversationId}/mark-conversation-as-done/`;
export const SEND_EMAIL_URL = `${API_URL}/conversations/{conversationId}/send-email/`;
export const CONVERSATION_LOG_URL = `${API_URL}/conversation_log/{conversationId}/`;
export const PHONE_NUMBERS_URL = `${API_URL}/phone/`;
export const PROVIDERS_URL = `${API_URL}/rest-auth/social-login-providers/`;
export const USERS_URL = `${API_URL}/users/`;
export const PERMISSION_GROUPS_URL = `${API_URL}/permission-groups/`;
export const LOGIN_PROVIDERS_URL = `${API_URL}/rest-auth/social-login-providers/available_providers/`;

export const GOOGLE_LOGIN_URL = `${API_URL}/rest-auth/dj-rest-auth/google/`;
export const MICROSOFT_LOGIN_URL = `${API_URL}/rest-auth/dj-rest-auth/microsoft/`;
