import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';
import {
  TbPlayerPlay, TbBellSearch, TbDownload,
} from 'react-icons/tb';
import { MdOutlineTranscribe } from 'react-icons/md';
import deepgramImage from '../../images/deepgram.jpeg';
import whisperImage from '../../images/openai.png';

function getButtonText(message) {
  if (message.reported) {
    return 'Reported';
  } if (message.type === 'bot') {
    return 'Report Validation';
  }
  return 'Report Transcript';
}

function TranscriptSegment({
  message, border, playPart, playing, index, reportMessage,
  downloadAudio, transcribeAudio, isSuperuser,
}) {
  return (
    <div className={`transcript-segment ${message.type} ${border ? 'border' : ''}`}>
      <div className="segment-header">
        <h4>
          <span>
            Message #
            {index + 1}
            {' - '}
          </span>
          {`${message.type === 'bot' ? 'Assistant' : 'Caller'}`}
          {(message.transcription_source === 'deepgram' || message.transcription_source === 'whisper')
          && (
          <span className="image-container">
            <img
              src={message.transcription_source === 'deepgram' ? deepgramImage : whisperImage}
              alt={message.transcription_source === 'deepgram' ? 'Deepgram Transcription' : 'Whisper Transcription'}
              width={20}
              height={20}
              title={message.transcription_source === 'deepgram'
                ? 'This text was generated by Deepgram Transcription' : 'This text was generated by OpenAI Whisper Transcription'}
            />
          </span>
          )}
        </h4>
        <h5>
          {Math.round(message.offset)}
          {' sec'}
        </h5>
      </div>
      <p>
        {message.content}
        {message.answer
            && message.type === 'bot' && (
            <>
              <br />
              <small>
                <b>{message.answer.is_valid ? 'Valid answer: ' : 'Invalid answer: '}</b>
                {message.answer.result}
              </small>
            </>
        )}
      </p>
      <Button
        onClick={playPart}
        className="btn btn-yellow btn-small"
        startIcon={<TbPlayerPlay />}
      >
        Play Part
      </Button>
      {playing && (
        <span className="playing">
          Current playing
        </span>
      )}

      {isSuperuser
        && message.type === 'user'
        && (
        <>
          <Button
            onClick={downloadAudio}
            className="btn btn-yellow btn-small"
            startIcon={<TbDownload />}
            tooltip="Download"
          >
            Download
          </Button>
          <Button
            onClick={transcribeAudio}
            className="btn btn-yellow btn-small"
            startIcon={<MdOutlineTranscribe />}
            tooltip="Download"
          >
            Transcribe
          </Button>
        </>
        )}

      <Button
        onClick={reportMessage}
        className={`
          btn btn-small right
          ${message.reported ? 'btn-red' : 'btn-yellow'}
        `}
        startIcon={<TbBellSearch />}
        tooltip="Report"
      >
        {getButtonText(message)}
      </Button>
    </div>
  );
}

// Define PropTypes for TranscriptSegment
TranscriptSegment.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    offset: PropTypes.number.isRequired,
    content: PropTypes.string.isRequired,
    answer: PropTypes.shape({
      is_valid: PropTypes.bool.isRequired,
      result: PropTypes.string.isRequired,
    }),
    reported: PropTypes.bool,
    transcription_source: PropTypes.string,
  }).isRequired,
  border: PropTypes.bool,
  playPart: PropTypes.func.isRequired,
  playing: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  reportMessage: PropTypes.func.isRequired,
  downloadAudio: PropTypes.func.isRequired,
  transcribeAudio: PropTypes.func.isRequired,
  isSuperuser: PropTypes.bool.isRequired,
};

TranscriptSegment.defaultProps = {
  border: false,
};

export default TranscriptSegment;
